import axios from 'axios';
import { GetPostsStatusResult } from 'common/interfaces/api';
import { useQuery } from 'react-query';
import { clone } from 'common/utils';
import { useEffect, useState } from 'react';
import usePostActionStore, { UploadStatus } from 'store/post_action';
import { useRouter } from 'next/router';
import PostActionItem from './PostActionItem';

const PostActionsList = (): JSX.Element => {
  const postActions = usePostActionStore((state) => state.postActions);
  const { pathname } = useRouter();
  const isLiveStream = pathname.startsWith('/livestream');
  const { data } = useQuery(
    ['getPostsStatus', postActions],
    async () => {
      const postIds = [];
      postActions.forEach((action) => {
        if (
          action.status === UploadStatus.FILE_PROCESSING &&
          action.postId &&
          action.postId !== 0
        )
          postIds.push(`ids=${action.postId}`);
      });
      if (postIds.length === 0) return null;
      const queryParam = `?${postIds.join('&')}`;
      return (await axios.get(`/posts/status${queryParam}`))
        .data as GetPostsStatusResult;
    },
    {
      refetchInterval: 30000,
      enabled: postActions.length > 0,
    }
  );

  const postsStatus = data?.data || [];
  const currentPostAction = usePostActionStore(
    (state) => state.currentPostAction
  );
  const mediaUploadingStatus = usePostActionStore(
    (state) => state.mediaUploadingStatus
  );
  const uploadUserMediaGlobal = usePostActionStore(
    (state) => state.uploadUserMediaGlobal
  );
  const [uploadedActionIds, setUploadedActionIds] = useState<number[]>([]);
  useEffect(() => {
    if (postActions.length === 0) return;
    if (currentPostAction && mediaUploadingStatus === UploadStatus.UPLOADING)
      return;
    const canStartAction = postActions.find(
      (action) =>
        action.status === UploadStatus.WAITING &&
        !uploadedActionIds.includes(action.id)
    );
    if (!canStartAction) return;

    void uploadUserMediaGlobal(canStartAction);
    setUploadedActionIds(
      (clone(uploadedActionIds) as number[]).concat([canStartAction.id])
    );
  }, [
    postActions,
    currentPostAction,
    uploadUserMediaGlobal,
    mediaUploadingStatus,
    uploadedActionIds,
  ]);

  if (isLiveStream) return <></>;
  return (
    <>
      {postActions.map((action) => {
        if (action.id !== currentPostAction?.id) {
          const status = postsStatus.find(
            (status) => status.id === action.postId
          );
          return (
            <PostActionItem
              postAction={action}
              key={`action-${action.id}`}
              status={status?.status}
              precheckLogs={status?.precheck_logs}
              precheckStatus={status?.precheck_status}
            />
          );
        }
      })}
    </>
  );
};
export default PostActionsList;
