import { SymbolAlertPeriod } from 'components/common/SymbolAlertSettingDialog';
import { ChartSymbol, ChartSymbolAlertData } from 'service/chart';
import {
  addEvent,
  EventAction,
  EventCategory,
  ScreenName,
} from './pp_tracking';
import { SymbolChart, VoteData } from 'common/interfaces/api';

export const hasSetting = (low: number, high: number) => {
  return low > 0 || high > 0;
};

export const alertSettingTitle = (period: SymbolAlertPeriod) => {
  switch (period) {
    case SymbolAlertPeriod.Daily:
      return '24時間';
    case SymbolAlertPeriod.Weekly:
      return '7日間';
    case SymbolAlertPeriod.Monthly:
      return '30日間';
  }
};

export const enableOptions = (data: ChartSymbolAlertData) => {
  const keys: string[] = [];
  if (data.vote_daily_high || data.vote_daily_low) {
    keys.push('vote_daily');
  }
  if (data.vote_weekly_high || data.vote_weekly_low) {
    keys.push('vote_weekly');
  }
  if (data.vote_monthly_high || data.vote_monthly_low) {
    keys.push('vote_monthly');
  }
  return keys;
};

export const isValidAlertSettings = (
  data: ChartSymbolAlertData,
  showingKeys: string[]
) => {
  if (
    showingKeys.length === 0 &&
    !data.prediction_week_gt_month &&
    !data.prediction_month_gt_week &&
    !data.izanavi_signal
  )
    return false;
  // vote
  if (
    showingKeys.includes('vote_daily') &&
    !!data.vote_daily_high &&
    !!data.vote_daily_low &&
    data.vote_daily_high <= data.vote_daily_low
  ) {
    return false;
  }
  if (
    showingKeys.includes('vote_daily') &&
    !data.vote_daily_high &&
    !data.vote_daily_low
  ) {
    return false;
  }
  if (
    showingKeys.includes('vote_weekly') &&
    !!data.vote_weekly_high &&
    !!data.vote_weekly_low &&
    data.vote_weekly_high <= data.vote_weekly_low
  ) {
    return false;
  }
  if (
    showingKeys.includes('vote_weekly') &&
    !data.vote_weekly_high &&
    !data.vote_weekly_low
  ) {
    return false;
  }
  if (
    showingKeys.includes('vote_monthly') &&
    !!data.vote_monthly_high &&
    !!data.vote_monthly_low &&
    data.vote_monthly_high <= data.vote_monthly_low
  ) {
    return false;
  }
  if (
    showingKeys.includes('vote_monthly') &&
    !data.vote_monthly_high &&
    !data.vote_monthly_low
  ) {
    return false;
  }
  return true;
};

export const caculateDislikePerForCSS = (likePercent: number) => {
  const dislikePer = 100 - likePercent;
  if (dislikePer <= 0) return 0;
  if (dislikePer >= 100) return 100;
  if (dislikePer <= 2.5) return 2.5;
  if (dislikePer >= 97.5) return 97.5;
  return dislikePer;
};

export const addVoteRankingClickEvent = ({
  position,
}: {
  position: string;
}) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Membership,
    screen: ScreenName.RankingSymbols,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        label: 'click_position',
        value: position,
      },
    ],
  });
};

export enum HighlightType {
  Top = 'top',
  Bottom = 'bottom',
  None = 'none',
}

export const getHighlightType = (sym: ChartSymbol) => {
  if (!sym.tab_show_highlight) return HighlightType.None;
  if (sym.tab_show_highlight?.length >= 2) {
    if (sym.position_in_ranking_tab === -1) {
      return HighlightType.Bottom;
    }
    if (sym.position_in_ranking_tab === 1) {
      return HighlightType.Top;
    }
  }
  return HighlightType.None;
};

export const highlightIconName = (type: HighlightType, name: string) => {
  if (type === HighlightType.Top) {
    return name;
  } else {
    return `${name}-purple`;
  }
};

export const caculateNewLikePer = (voteData: VoteData) => {
  if (!voteData) return 0;
  return voteData.thumbs_ups_percentage;
};

export enum SymbolTabsName {
  VoteResult = 'vote',
  ScoringModel = 'scoring',
}

export const signalToCode = (signal: string) => {
  switch (signal) {
    case 'SELL':
      return 'bit';
    case 'BUY':
      return 'many';
    case 'HOLD':
      return 'normal';
    default:
      return '';
  }
};

export const signalToText = (signal: string) => {
  switch (signal) {
    case 'SELL':
      return '少';
    case 'BUY':
      return '多';
    case 'HOLD':
      return '普通';
    default:
      return '';
  }
};

export const isSupportIzanaviSymbol = (symbol: SymbolChart) => {
  return !!symbol?.is_izanavi_supporting;
};
