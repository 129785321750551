import { Rating } from '@material-ui/lab';
import classNames from 'classnames';
import { ratingNumberWithDecimal } from 'common/utils/course';
import styles from './CourseRatingView.module.scss';
const CourseRatingView = ({
  count,
  rating,
  className,
}: {
  count: number;
  rating: number;
  className?: string;
}) => {
  return (
    <div
      className={classNames(styles.ratingSection, { [className]: !!className })}
    >
      <span className={styles.rateValue}>
        {ratingNumberWithDecimal(rating)}
      </span>
      <Rating name="ratingView" value={rating} size="large" readOnly />
      <span className={styles.ratingCount}>({count || 0})</span>
    </div>
  );
};

export default CourseRatingView;
