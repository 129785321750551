import React, { useState, useMemo, useEffect } from 'react';
import usePostActionStore, {
  PostActionType,
  uploadingStatusText,
  UploadStatus,
} from 'store/post_action';
import { MediaType, PrecheckLog, PrecheckStatus } from 'common/interfaces/api';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import useStore from 'store/timeline';
import Icon from 'components/common/Icon';
import ConfirmDialog from 'components/common/ConfirmDialog';
import styles from './PostActionItem.module.scss';
import HelpPopover from 'components/common/HelpPopover';
import { POST_POLICY_URL, precheckPolicyNames } from 'common/utils';
import { useRouter } from 'next/router';

const PostActionItem = ({
  postAction,
  status,
  precheckLogs,
  precheckStatus,
}: {
  postAction: PostActionType;
  status: string | undefined;
  precheckLogs?: PrecheckLog[];
  precheckStatus?: PrecheckStatus;
}): JSX.Element => {
  const currentUser = useStore((state) => state.currentUser);
  const router = useRouter();
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const deletePostAction = usePostActionStore(
    (state) => state.deletePostAction
  );
  const refetchPosts = useStore((state) => state.refetchPosts);
  const refetchProfile = useStore((state) => state.refetchProfile);
  // const reloadPostAction = usePostActionStore(
  //   (state) => state.reloadPostAction
  // );
  const mediaFile = postAction.params?.media[0];

  const previewImage = useMemo(() => {
    if (!mediaFile) return <div className={styles.blank}></div>;
    if (mediaFile.type === MediaType.Audio) {
      return (
        <div className={styles.mediaAudio}>
          {mediaFile.data.thumbnail_url && (
            <Image
              src={mediaFile.data.thumbnail_url}
              layout="fill"
              objectFit="cover"
            />
          )}
          <Icon name="audio-icon" width={22} height={26} />
        </div>
      );
    }
    if (mediaFile.type === MediaType.Image) {
      return (
        <div className={styles.mediaThumb}>
          <Image src={mediaFile.data.url} layout="fill" objectFit="cover" />
        </div>
      );
    }
    if (
      (mediaFile.type === MediaType.Video ||
        mediaFile.type === MediaType.Link) &&
      mediaFile.data.thumbnail_url
    ) {
      return (
        <div className={styles.mediaThumb}>
          <Image
            src={mediaFile.data.thumbnail_url}
            layout="fill"
            objectFit="cover"
          />
          {mediaFile.type === MediaType.Video && (
            <Icon name="play" width={22} height={26} />
          )}
        </div>
      );
    }
    return <div className={styles.blank}></div>;
  }, [mediaFile]);
  useEffect(() => {
    if (
      [PrecheckStatus.SuperHigh, PrecheckStatus.High].includes(precheckStatus)
    ) {
      refetchProfile();
      if (router.asPath === `/${currentUser?.username}`) {
        refetchPosts();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precheckStatus]);
  if (!postAction) return <></>;

  const currentStatus =
    status === 'activated'
      ? UploadStatus.DONE
      : status === 'disabled'
      ? UploadStatus.DISABLED
      : postAction.status;
  const isError =
    currentStatus === UploadStatus.POST_FAILED ||
    currentStatus === UploadStatus.UPLOAD_FAILED;

  const deleteFromList = () => {
    deletePostAction(postAction);
  };

  // const reloadAction = () => {
  //   reloadPostAction(postAction);
  // };

  const cancelAction = () => {
    deletePostAction(postAction);
  };

  return (
    <div className={styles.uploadProgress}>
      <div className={styles.mediaLoading}>
        {previewImage}
        <div className={styles.progressGroup}>
          <div className={styles.status}>
            {uploadingStatusText(currentStatus)}
          </div>
          <div className={styles.progressBar}>
            <div
              className={cn(styles.progress, { [styles.error]: isError })}
              style={{
                width: `${currentStatus === UploadStatus.WAITING ? 0 : 100}%`,
              }}
            />
          </div>
        </div>
        <div className={styles.btnActionGroup}>
          {currentStatus === UploadStatus.WAITING && (
            <div className={styles.icon}>
              <Icon
                name="delete-trash"
                width={24}
                height={24}
                onClick={() => setShowCancelConfirmModal(true)}
              />
            </div>
          )}
          {currentUser &&
            postAction.postId !== 0 &&
            currentStatus === UploadStatus.DONE && (
              <Link
                href={`/${currentUser?.username}/posts/${postAction.postId}`}
                passHref
              >
                <div className={styles.icon}>
                  <Icon
                    name="edit-gray"
                    width={24}
                    height={24}
                    onClick={() => deleteFromList()}
                  />
                </div>
              </Link>
            )}
          {currentStatus === UploadStatus.DONE ? (
            <>
              {!precheckLogs ? (
                <div className={styles.icon}>
                  <Icon
                    name="done"
                    width={24}
                    height={24}
                    onClick={() => deleteFromList()}
                  />
                </div>
              ) : (
                precheckStatus === PrecheckStatus.High && (
                  <div className={styles.icon}>
                    <HelpPopover
                      iconElement={
                        <Icon name="warning" width={24} height={24} />
                      }
                      tooltipClassname={styles.tooltip}
                      tooltipWidth={220}
                      content={
                        <>
                          メディア投稿に{precheckPolicyNames(precheckLogs)}
                          が含まれています。投稿ポリシーに違反している可能性があるため、投稿タイプを無料投稿に変更させていただきました。詳しくは、
                          <a target="_blank" href={POST_POLICY_URL}>
                            投稿に関するポリシー
                          </a>
                          をご覧ください。
                        </>
                      }
                      className={styles.imageWarning}
                    />
                  </div>
                )
              )}
            </>
          ) : currentStatus === UploadStatus.DISABLED ? (
            <>
              {precheckStatus === PrecheckStatus.SuperHigh && (
                <div className={styles.icon}>
                  <HelpPopover
                    iconElement={<Icon name="warning" width={24} height={24} />}
                    tooltipClassname={styles.tooltip}
                    tooltipWidth={220}
                    content={
                      <>
                        メディア投稿に{precheckPolicyNames(precheckLogs)}
                        が含まれています。投稿ポリシーに違反している可能性があるため、投稿が非表示されました。詳しくは、
                        <a target="_blank" href={POST_POLICY_URL}>
                          投稿に関するポリシー
                        </a>
                        をご覧ください。
                      </>
                    }
                    className={styles.imageWarning}
                  />
                </div>
              )}
              <div className={styles.icon}>
                <Icon
                  name="delete-trash"
                  width={24}
                  height={24}
                  onClick={() => deleteFromList()}
                />
              </div>
            </>
          ) : (
            isError && (
              <>
                {/* <div className={styles.icon}>
                  <Icon
                    name="reload"
                    width={24}
                    height={24}
                    onClick={() => reloadAction()}
                  />
                </div> */}
                <div className={styles.icon}>
                  <Icon
                    name="delete-trash"
                    width={24}
                    height={24}
                    onClick={() => deleteFromList()}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
      {showCancelConfirmModal && (
        <ConfirmDialog
          open={showCancelConfirmModal}
          actionCloseFnc={() => setShowCancelConfirmModal(false)}
          actionSelectedFnc={cancelAction}
          title="投稿をキャンセルしますか？"
          noText="いいえ"
          yesText="はい"
        />
      )}
    </div>
  );
};

export default React.memo(PostActionItem);
