import { useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';
// import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import Icon from 'components/common/Icon';
import InfiniteScroll from 'components/common/InfiniteScroll';

import styles from './CoursesList.module.scss';
import { PostsLoader } from 'components/common/PreloadContentLoader';
import { CourseInfo, getPurchasedCoursesList } from 'service/course';
import CourseItemView from '../common/CourseItemView';
export type Props = {
  disableInfiniteScroll?: boolean;
  noResultMessage: string;
};

export default function PurchasedCoursesList({
  disableInfiniteScroll,
  noResultMessage,
}: Props): JSX.Element {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const { isLoading, data, fetchNextPage, isFetchingNextPage, isFetched } =
    useInfiniteQuery(
      ['getPurchasedCoursesList'],
      async ({ pageParam = 1 }) => {
        if (!isAuthInit) return Promise.reject();
        return await getPurchasedCoursesList(pageParam);
      },
      {
        getNextPageParam: (lastPage, pages) => {
          const courses = lastPage?.data?.courses;
          if (courses && courses.length > 0) {
            return pages.length + 1;
          }
        },
        // TODO: somehow enabled is not working as intended
        // useInfiniteQuery still runs when enabled is false
        // this is currently handled by only shows this component when isAuthInit is true
        enabled: isAuthInit,
        refetchOnWindowFocus: false,
      }
    );

  const pages = data?.pages;
  const courses: CourseInfo[] = useMemo(
    () =>
      pages
        ? pages.reduce<CourseInfo[]>((curr, page) => {
            return curr.concat(page.data?.courses || []);
          }, [])
        : [],
    [pages]
  );

  let noPostPlaceholder;
  if (!isLoading && courses.length === 0) {
    noPostPlaceholder = (
      <div className={styles.noCourse}>
        <Icon name="empty" width={96} height={96} />
        <div dangerouslySetInnerHTML={{ __html: noResultMessage }}></div>
      </div>
    );
  }

  const coursesRender = (
    <>
      {courses.map((course, index) => {
        return (
          <CourseItemView course={course} key={`${course?.id}-${index}`} />
        );
      })}
    </>
  );

  return (
    <div className={styles.coursesListWrapper}>
      {noPostPlaceholder}
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        disabled={disableInfiniteScroll}
        isLoading={isFetchingNextPage}
      >
        {isFetched ? coursesRender : <PostsLoader />}
      </InfiniteScroll>
    </div>
  );
}
