/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import { PostParentKey } from 'common/interfaces/api';
import getConfig from 'next/config';
import parser from 'ua-parser-js';
import { parseJSON, stringifyJSON } from '.';
import {
  MAX_TRACKING_EVENTS_COUNT,
  TRACKING_EVENTS_KEY_NAME,
} from './index.constant';

export enum EventAction {
  Click = 'click',
  PageView = 'pageview',
}

export enum EventCategory {
  Account = 'account',
  Membership = 'membership',
  Tutorial = 'tutorial',
  Survey = 'survey',
  Error = 'error',
  Agent = 'agent',
  Campaign = 'campaign',
  AiRecommend = 'ai_recommend',
  Course = 'course',
  WebAnalytics = 'web_analytics',
  AnimeCharacter = 'ai_character',
}

export enum EventComponent {
  VoteView = 'vote_view',
  MembershipBanner = 'membership_banner',
  MembershipPaywall = 'membership_paywall',
  GoldMembershipFreeTrialBanner = 'gold_membership_free_trial_banner',
  PlatinumMembershipFreeTrialBanner = 'platinum_membership_free_trial_banner',
  CreatorDiscountCampaignBanner = 'discount_75_percentage_campaign_banner',
  CoinBackCampaignBanner = 'coin_back_campaign_banner',
  CoinBackCampaignDialog = 'coin_back_campaign_dialog',
  Discount50PercentCampaignBanner = 'discount_50_percentage_campaign_banner',
  Discount50PercentCampaignDialog = 'discount_50_percentage_campaign_dialog',
  DiscountBondsChannelBanner = 'discount_bond_channel_banner',
  DiscountBondsChannelDialog = 'discount_bond_channel_dialog',
  ProNotificationPRDialog = 'pro_notification_pr_dialog',
  ProNotificationPRBanner = 'pro_notification_pr_banner',
  GoldNewFeatureBanner = 'gold_new_feature_banner',
  Discount100YenSilverCampaignBanner = 'discount_100_yen_silver_campaign_banner',
  Discount100YenSilverCampaignDialog = 'discount_100_yen_silver_campaign_dialog',
  VideoPlayer = 'video_player',
  AudioPlayer = 'audio_player',
  CoinSummaryDialog = 'coin_summary_dialog',
  SelectTopicDialog = 'select_topic_dialog',
  NewOnboardingDialog = 'new_onboarding_dialog',
  MissionsDialog = 'missions_dialog',
  BadgeSystemDialog = 'badge_system_dialog',
  ChartIntroduceDialog = 'chart_introduce_dialog',
  GlobalHeader = 'global_header',
  SurveyBanner = 'survey_banner',
  SurveyDialog = 'survey_dialog',
  FreePaidPostOnboardingDialog = 'free_paid_post_onboarding_dialog',
  NewOnboardingDialogV3 = 'new_onboarding_dialog_v3',
  LoginDialog = 'login_dialog',
  AgentFunctionPopupIntro = 'first_recruitment_intro_popup',
  AgentFunctionPopupRemind = 'remind_recruitment_intro_popup',
  AgentFunctionPolicyPopup = 'recruitment_policy_popup',
  PaymentDialog = 'payment_dialog',
  TippingDialog = 'tipping_dialog',
  AddCreditCardDialog = 'add_credit_card_dialog',
  SubscribingStatusModal = 'subscribing_status_modal',
  PostItem = 'post_view',
  CoursePaywall = 'course_paywall',
  ChangeProvider = 'change_provider',
  IGMissionLP = 'ig_mission_lp',
  AlarmSettingDialog = 'anime_alarm_setting_dialog',
  AlarmOnboardingDialog = 'anime_alarm_onboarding_dialog',
  AlarmNotificationBanner = 'anime_alarm_notification_banner',
  AnimeCharacter = 'anime_character',
  AnimeFunctionBanner = 'anime_function_banner',
  AnimeCharacterLevelUpDialog = 'anime_character_level_up_dialog',
  DiscountCreatorsList = 'discount_creators_list',
  DiscountCampaignUserPopup = 'discount_campaign_user_popup',
  DiscountCampaignCreatorPopup = 'discount_campaign_creator_popup',
  MinkabuBanner = 'minkabu_banner',
}

export type PostProperties = {
  post_id?: number;
  source_type?: string;
  source_name?: string;
  source_version?: string;
};

export type TrackingEvent = {
  action: EventAction;
  category: EventCategory;
  component?: EventComponent | string;
  screen: ScreenName | string;
  timestamp: string;
  post_properties?: PostProperties;
  tracking_params: { label: string; value: string }[];
};

export enum ScreenName {
  HomeTimeline = 'home_timeline',
  RecommendCreators = 'recommend_creators',
  SavedPosts = 'saved_posts',
  PaidPosts = 'paid_posts',
  AllPosts = 'all_posts',
  UnreadPost = 'unread_posts',
  RelatedPosts = 'related_posts',
  Profile = 'profile',
  RecruitmentProfile = 'recruitment_profile',
  PostDetail = 'post_detail',
  SearchResults = 'search_results',
  SpotlightUsersSection = 'spotlight_users_section',
  TopicPosts = 'topic_posts',
  TrendingPosts = 'trending_posts',
  SymbolRelatedPosts = 'symbol_related_posts',
  TrendingTopicPosts = 'trending_topic_posts',
  RankingCreators = 'ranking_creators',
  ProfileViewdUsers = 'profile_viewed_users',
  Market = 'market',
  SymbolDetail = 'symbol_detail',
  FavoriteSymbols = 'favorite_symbols',
  RankingSymbols = 'ranking_symbols',
  LiveStreamPosts = 'livestream_posts',
  HotKeywords = 'hot_keywords',
  HotKeyWordPosts = 'hot_keyword_posts',
  LandingPage = 'landing_page',
  AccountSetting = 'account_settings',
  AnimeSetting = 'anime_settings',
  HelpSetting = 'help_settings',
  OtherSettings = 'other_settings',
  MembershipPage = 'membership_page',
  Notification = 'notification',
  Page500 = 'page_500',
  CourseDetailsPage = 'course_details_page',
  IGMissionLP = 'ig_mission_lp_page',
  PredictionFollowers = 'prediction_followers',
  CoinBackPage = 'coin_back_page',
  MembershipCancelPage = 'membership_cancel_page',
  SymbolAlertSetting = 'symbol_alert_setting',
  SilverDiscountCampaign = 'silver_discount_campaign',
  IzanaviPRDialog = 'izanavi_pr_dialog',
}

export const parentKeyToScreenName = (key: PostParentKey | string) => {
  switch (key) {
    case PostParentKey.Timeline:
      return ScreenName.HomeTimeline;
    case PostParentKey.AllPosts:
      return ScreenName.AllPosts;
    case PostParentKey.PaidPosts:
      return ScreenName.PaidPosts;
    case PostParentKey.UnReadPosts:
      return ScreenName.UnreadPost;
    case PostParentKey.PostDetail:
      return ScreenName.PostDetail;
    case PostParentKey.SavePosts:
      return ScreenName.SavedPosts;
    case PostParentKey.SearchResults:
      return ScreenName.SearchResults;
    case PostParentKey.RelatedPosts:
      return ScreenName.RelatedPosts;
    case PostParentKey.SpotlightUsers:
      return ScreenName.SpotlightUsersSection;
    case PostParentKey.SymbolRelatedPosts:
      return ScreenName.SymbolRelatedPosts;
    case PostParentKey.TopicPosts:
      return ScreenName.TopicPosts;
    case PostParentKey.TrendingPosts:
      return ScreenName.HomeTimeline;
    case PostParentKey.ProfilePosts:
      return ScreenName.Profile;
    default:
      return key;
  }
};

export const badgeSystemDialogClick = (
  position:
    | 'step1_next_btn'
    | 'step2_next_btn'
    | 'step3_next_btn'
    | 'close_button'
) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Tutorial,
    screen: ScreenName.HomeTimeline,
    component: EventComponent.BadgeSystemDialog,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export const chartIntroClick = (
  position: 'step1_next_btn' | 'step2_next_btn' | 'step3_next_btn' | 'skip_btn'
) => {
  addEvent({
    action: EventAction.Click,
    category: EventCategory.Tutorial,
    screen: ScreenName.HomeTimeline,
    component: EventComponent.ChartIntroduceDialog,
    timestamp: new Date().toISOString(),
    tracking_params: [
      {
        value: position,
        label: 'click_position',
      },
    ],
  });
};

export default class PostPrimeTracking {
  appId: string;
  deviceModel: string;
  osVersion: string;
  browserVersion: string;
  platform: string;
  osName: string;
  userLanguage: string;
  version: string;
  browserName: string;
  constructor() {
    this.appId = 'web_app';
    this.deviceModel = 'web';
    this.platform = 'web_app';
    this.userLanguage = 'ja-JP';
    this.version = 'None';
    this.osName = 'None';
    this.browserName = 'None';
    this.browserVersion = 'None';
    this.osVersion = 'None';
  }
  init = () => {
    try {
      const { publicRuntimeConfig } = getConfig();
      this.version = publicRuntimeConfig.version;
      // get user locale
      const userLocale =
        navigator.languages && navigator.languages.length > 0
          ? navigator.languages[0]
          : navigator.language;
      if (userLocale) this.userLanguage = userLocale;
      // get user device info
      const ua = parser(navigator.userAgent);
      this.osName = ua.os?.name || 'None';
      this.deviceModel =
        [ua.device?.vendor || '', ua.device?.type || '', ua.device?.model || '']
          .filter((data) => !!data)
          .join('-') || 'None';
      this.browserVersion = ua.browser?.version || 'None';
      this.browserName = ua.browser?.name || 'None';
      this.osVersion = ua.os?.version || 'None';
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  send = (events: TrackingEvent[]) => {
    return (
      axios
        .post(`/tracks/user-events`, {
          app_id: this.appId,
          device_model: this.deviceModel,
          os_version: this.osVersion,
          platform: this.platform,
          os_name: this.osName,
          browser_name: this.browserName,
          browser_version: this.browserVersion,
          tracking_events: events,
          user_language: this.userLanguage,
          version: this.version,
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
    );
  };
}

export const sendUserEvents = (events: TrackingEvent[]) => {
  try {
    if (typeof window === 'undefined') return;
    if (!(window as any).postPrimeTracking) return;
    (window as any).postPrimeTracking?.send(events);
    localStorage.setItem(TRACKING_EVENTS_KEY_NAME, stringifyJSON([]));
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const addEvent = (event: TrackingEvent) => {
  if (process.env.NODE_ENV === 'development') return;
  if (typeof window === 'undefined') return;
  try {
    const logEventData = localStorage.getItem(TRACKING_EVENTS_KEY_NAME) || '[]';
    const currentEvents = (parseJSON(logEventData) || []) as TrackingEvent[];
    currentEvents.push(event);
    if (currentEvents.length >= MAX_TRACKING_EVENTS_COUNT) {
      sendUserEvents(currentEvents);
    } else {
      localStorage.setItem(
        TRACKING_EVENTS_KEY_NAME,
        stringifyJSON(currentEvents)
      );
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
    console.log(error);
  }
};
