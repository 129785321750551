import { useState } from 'react';
import usePostActionStore, {
  uploadingStatusText,
  UploadStatus,
} from 'store/post_action';
import { MediaType } from 'common/interfaces/api';
import Image from 'next/image';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Icon from '../Icon';
import styles from './GlobalUploadingProgress.module.scss';

const GlobalUploadingProgress = (): JSX.Element => {
  const currentPostAction = usePostActionStore(
    (state) => state.currentPostAction
  );
  const mediaUploadingProgress = usePostActionStore(
    (state) => state.mediaUploadingProgress
  );
  const mediaUploadingStatus = usePostActionStore(
    (state) => state.mediaUploadingStatus
  );
  const cancelPostAction = usePostActionStore(
    (state) => state.cancelPostAction
  );
  const axiosCancelToken = usePostActionStore(
    (state) => state.axiosCancelToken
  );
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);

  if (!currentPostAction) return <></>;
  const mediaFile = currentPostAction.params.media[0];
  const previewImage =
    mediaFile.type === MediaType.Video ? (
      <div className={styles.mediaVideo}>
        <Image
          src={mediaFile.data.thumbnail_url}
          layout="fill"
          objectFit="cover"
        />
      </div>
    ) : (
      <div className={styles.mediaAudio}>
        <Icon name="audio-pause-on" width={40} height={40} />
      </div>
    );

  const cancelAction = () => {
    setShowCancelConfirmModal(false);
    axiosCancelToken.cancel();
    cancelPostAction(currentPostAction);
  };

  return (
    <div className={styles.uploadProgress}>
      <div className={styles.mediaLoading}>
        {previewImage}
        <div className={styles.progressGroup}>
          <div className={styles.status}>
            {uploadingStatusText(mediaUploadingStatus)}{' '}
            {mediaUploadingStatus === UploadStatus.UPLOADING &&
              mediaUploadingProgress.toString() + '%'}
          </div>
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: mediaUploadingProgress.toString() + '%' }}
            />
          </div>
        </div>
        <div className={styles.btnActionGroup}>
          {mediaUploadingStatus === UploadStatus.UPLOADING && (
            <div className={styles.icon}>
              <Icon
                name="delete-trash"
                width={30}
                height={30}
                onClick={() => setShowCancelConfirmModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      {showCancelConfirmModal && (
        <ConfirmDialog
          open={showCancelConfirmModal}
          actionCloseFnc={() => setShowCancelConfirmModal(false)}
          actionSelectedFnc={cancelAction}
          title="投稿をキャンセルしますか？"
          noText="いいえ"
          yesText="はい"
        />
      )}
    </div>
  );
};

export default GlobalUploadingProgress;
