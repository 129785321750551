import { MouseEventHandler } from 'react';
import styles from './Icon.module.scss';
import Image from 'next/image';

export type Props = {
  name: string;
  isOn?: boolean;
  width: number;
  height: number;
  onClick?: MouseEventHandler;
  hasOn?: boolean;
  hasHover?: boolean;
};

export default function Icon({
  name,
  width,
  height,
  isOn = false,
  onClick,
  hasOn = false,
  hasHover = false,
}: Props): JSX.Element {
  let imgSrc = `/images/icons/${name}.svg`;
  if (hasOn) {
    imgSrc = isOn
      ? `/images/icons/${name}-on.svg`
      : `/images/icons/${name}.svg`;
  }
  const hoverSrc = isOn
    ? `/images/icons/${name}-on-hover.svg`
    : `/images/icons/${name}-hover.svg`;
  let onMouseOver: MouseEventHandler<HTMLImageElement>;
  let onMouseOut: MouseEventHandler<HTMLImageElement>;
  if (hasHover) {
    onMouseOver = (e) => (e.currentTarget.src = hoverSrc);
    onMouseOut = (e) => (e.currentTarget.src = imgSrc);
  }

  return (
    <div
      className={styles.iconWrapper}
      style={{ cursor: onClick ? 'pointer' : 'unset' }}
      tabIndex={onClick ? 0 : -1}
    >
      <Image
        src={imgSrc}
        width={width}
        height={height}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        alt={name}
      />
    </div>
  );
}

export const DoneIcon = ({
  width = 18,
  height = 18,
}: {
  width: number;
  height: number;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <circle cx="12" cy="12" r="12" fill="#0ABC71" />
      <path
        d="M6 13.4356L9.61592 17.0516L17.9995 8.66797"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
