/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { MembershipPackageName } from 'common/interfaces/api';
import { parse, serialize } from 'cookie';
import { randomUid } from 'lib/uuid';
import { ParsedUrlQuery } from 'querystring';
import { isProduction } from '.';

// Serverside
export const generateCookieList = (
  query: ParsedUrlQuery,
  cookies: { [key: string]: string }
) => {
  let cookiesList: string[] = [];
  const atss = query.atss as string;
  const a8 = query.a8 as string;
  // const gmo_google = query.gmo_google as string;
  // const google_lclo = query.utm_source === 'google_lclo';
  // const gmo_yahoo = query.utm_source === 'gmo_yahoo';
  // const lclo_yahoo = query.utm_source === 'lclo_yahoo';
  if (atss) {
    cookiesList = cookiesList.concat([
      serialize('atss', atss, {
        maxAge: 90 * 24 * 60 * 60,
        httpOnly: true,
        secure: true,
      }),
      serialize('atss_js', atss, {
        maxAge: 90 * 24 * 60 * 60,
        httpOnly: false,
      }),
    ]);
    if (!cookies.atss_verify) {
      cookiesList.push(
        serialize('atss_verify', randomUid(), {
          maxAge: 90 * 24 * 60 * 60,
          httpOnly: false,
        })
      );
    }
  }
  if (a8) {
    if (!cookies.a8_verify) {
      cookiesList.push(
        serialize('a8_verify', randomUid(), {
          maxAge: 90 * 24 * 60 * 60,
          httpOnly: false,
        })
      );
    }
    if (!cookies.a8_param) {
      cookiesList.push(
        serialize('a8_param', a8, {
          maxAge: 90 * 24 * 60 * 60,
          httpOnly: false,
        })
      );
    }
  }
  // if (gmo_google) {
  //   if (!cookies.gmo_google) {
  //     cookiesList.push(
  //       serialize('gmo_google', gmo_google, {
  //         maxAge: 24 * 60 * 60,
  //         httpOnly: false,
  //       })
  //     );
  //     const keyword = query.keyword as string;
  //     if (keyword) {
  //       cookiesList.push(
  //         serialize('gmo_google_keyword', keyword, {
  //           maxAge: 24 * 60 * 60,
  //           httpOnly: false,
  //         })
  //       );
  //     }
  //   }
  // }

  // if (google_lclo) {
  //   if (!cookies.google_lclo) {
  //     cookiesList.push(
  //       serialize('google_lclo', randomUid(), {
  //         maxAge: 24 * 60 * 60,
  //         httpOnly: false,
  //       })
  //     );
  //   }
  // }
  // if (gmo_yahoo) {
  //   if (!cookies.gmo_yahoo) {
  //     cookiesList.push(
  //       serialize('gmo_yahoo', randomUid(), {
  //         maxAge: 24 * 60 * 60,
  //         httpOnly: false,
  //       })
  //     );
  //   }
  // }

  // if (lclo_yahoo) {
  //   if (!cookies.lclo_yahoo) {
  //     cookiesList.push(
  //       serialize('lclo_yahoo', randomUid(), {
  //         maxAge: 24 * 60 * 60,
  //         httpOnly: false,
  //       })
  //     );
  //   }
  // }
  if (query.utm_source) {
    cookiesList.push(
      serialize('utm_source', query.utm_source as string, {
        maxAge: 90 * 24 * 60 * 60,
        httpOnly: false,
      })
    );
  }
  if (query.utm_campaign) {
    cookiesList.push(
      serialize('utm_campaign', query.utm_campaign as string, {
        maxAge: 90 * 24 * 60 * 60,
        httpOnly: false,
      })
    );
  }
  if (query.utm_medium) {
    cookiesList.push(
      serialize('utm_medium', query.utm_medium as string, {
        maxAge: 90 * 24 * 60 * 60,
        httpOnly: false,
      })
    );
  }
  return cookiesList;
};

export const generateTrackingParam = (cookie: { [key: string]: string }) => {
  let utm_source = '';
  let utm_medium = '';
  let utm_campaign = '';
  if (cookie.a8_verify) {
    utm_source = 'A8';
    utm_campaign = cookie.a8_verify;
  } else if (cookie.atss_js) {
    utm_source = 'AccessTrade';
    utm_campaign = cookie.atss_verify;
    try {
      utm_medium = cookie.atss_js?.split('-')[0];
    } catch (error) {
      utm_medium = cookie.atss_js;
    }
  } else {
    utm_source = cookie.utm_source || '';
    utm_medium = cookie.utm_medium || '';
    utm_campaign = cookie.utm_campaign || '';
  }
  //  else if (cookie.gmo_google) {
  //   utm_source = 'GMOGoogle';
  //   utm_medium = cookie.gmo_google;
  //   utm_campaign = decodeURI(cookie.gmo_google_keyword);
  // }
  return {
    utm_source,
    utm_medium,
    utm_campaign,
  };
};

// call after prime sub success
export const trackingPPSubCV = () => {
  if (!isProduction()) return;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        send_to: 'AW-10835403462/GHGyCKnY58EZEMbF3K4o',
      });
    }
  } catch (error) {}
};

// call after membership sub success
export const trackingMembershipCV = () => {
  if (!isProduction()) return;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if ((window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        send_to: 'AW-10835403462/j8-iCP-85cEZEMbF3K4o',
      });
    }
  } catch (error) {}
};

export const rentracksCVTrigger = (info: string) => {
  if (!isProduction()) return;
  try {
    const loadScriptRTCV = (callback: () => void) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const script = document.createElement('script') as any;
      script.type = 'text/javascript';
      script.src = `https://www.rentracks.jp/js/itp/rt.track.js?t=${new Date().getTime()}`;
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        script.onload = function () {
          callback();
        };
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    loadScriptRTCV(function () {
      (window as any)._rt.sid = 7599;
      (window as any)._rt.pid = 10772;
      (window as any)._rt.price = 0;
      (window as any)._rt.reward = -1;
      (window as any)._rt.cname = '';
      (window as any)._rt.ctel = '';
      (window as any)._rt.cemail = '';
      (window as any)._rt.cinfo = info;
      (window as any).rt_tracktag();
    });
  } catch (error) {}
};

export const rentracksLPTrigger = () => {
  if (!isProduction()) return;
  try {
    const loadScriptRTCV = (callback: () => void) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const script = document.createElement('script') as any;
      script.type = 'text/javascript';
      script.src = `https://www.rentracks.jp/js/itp/rt.track.js?t=${new Date().getTime()}`;
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        script.onload = function () {
          callback();
        };
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loadScriptRTCV(function () {});
  } catch (error) {}
};

// export const yahooCvPPSubTrigger = () => {
//   if (!isProduction()) return;
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//   if (window && (window as any).ytag) {
//     (window as any).ytag({
//       type: 'yss_conversion',
//       config: {
//         yahoo_conversion_id: '1001259844',
//         yahoo_conversion_label: '3h2CCPy6i9sDEJCI7vAo',
//         yahoo_conversion_value: '0',
//       },
//     });
//   }
// };

const a8CvCodeCreator = (username: string) => {
  if (!username) return '';
  if (
    [
      'PostPrimeJP',
      'PostPrimeUS',
      'forex',
      'crypto',
      'cmdty',
      'dantakahashi',
    ].includes(username)
  ) {
    return username;
  }
  return '';
};

export const a8CvTrigger = (username: string, userId: string) => {
  if (!isProduction()) return;
  try {
    const code = a8CvCodeCreator(username);
    if (!code) return;
    const cookie = parse(document?.cookie);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (cookie.a8_verify && window && (window as any).a8sales) {
      const token = `${userId}_${cookie.a8_verify}`.slice(0, 50);
      (window as any).a8sales({
        pid: 's00000024047002',
        order_number: token,
        currency: 'JPY',
        items: [
          {
            code: code,
            price: 1,
            quantity: 1,
          },
        ],
        total_price: 1,
      });
    }
  } catch (error) {}
};

export const a8CvTriggerMembership = (
  packageName: MembershipPackageName,
  userId: string
) => {
  if (!isProduction()) return;
  try {
    const cookie = parse(document?.cookie);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (cookie.a8_verify && window && (window as any).a8sales) {
      const token = `${userId}_${cookie.a8_verify}`.slice(0, 50);
      const code = `${packageName.replace('-', '_')}`;
      (window as any).a8sales({
        pid: 's00000024047002',
        order_number: token,
        currency: 'JPY',
        items: [
          {
            code: code,
            price: 1,
            quantity: 1,
          },
        ],
        total_price: 1,
      });
    }
  } catch (error) {}
};

// export const twitterCvTrigger = () => {
//   if (!isProduction()) return;
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//   if (window && (window as any).twq) {
//     (window as any).twq('event', 'tw-ocbgs-ofzas', {
//       currency: 'JPY',
//     });
//   }
// };

// export const nursingLPGoogleCvTrigger = () => {
//   if (!isProduction()) return;
//   try {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//     if ((window as any).gtag) {
//       (window as any).gtag('event', 'conversion', {
//         send_to: 'AW-10835403462/_14HCN7jvPcYEMbF3K4o',
//       });
//     }
//   } catch (error) {}
// };
