import axios from 'axios';
import { User } from 'common/interfaces/api';
import { NewMedia } from './media';
import { TopicType } from './user';

export interface ChapterType {
  thumbnail_media: NewMedia;
  detail: string;
  id?: number;
  start_position: number;
  thumbnail_image_id?: string;
}

export interface AnswerType {
  detail: string;
  is_correct: boolean;
  id?: number;
}

export interface QuestionType {
  id?: number;
  detail: string;
  lecture_id?: number;
  answers: AnswerType[];
  user_answer_id?: number;
}

export enum LecturePaidType {
  Free = 'free',
  Paid = 'paid',
}
export interface LectureType {
  content_access?: boolean;
  description: string;
  id: number;
  lecture_media: NewMedia;
  lecture_thumbnail_media: NewMedia;
  title: string;
  type: LecturePaidType;
  chapters: ChapterType[];
  questions: QuestionType[];
  position?: number;
  is_viewed: boolean;
  view_count: number;
  token: string;
}

export enum CourseStatus {
  Draft = 'draft',
  Approved = 'approved',
  Resubmit = 'resubmit',
  Rejected = 'rejected',
  Submitted = 'submitted',
}
export interface CourseInfo {
  benefits: string[];
  description: string;
  id: number;
  active: boolean;
  lectures: LectureType[];
  domains: TopicType[];
  required_background: string;
  thumbnail_image_media: NewMedia;
  title: string;
  status: CourseStatus;
  user: User;
  target_user: string;
  web_amount: number;
  admin_comment?: string;
  is_owner?: boolean;
  review?: CourseReviewItem;
  is_purchased?: boolean;
  published_at: string;
  is_editable?: boolean;
  review_summary: {
    count: number;
    rating: number;
    ratings: { count: number; rating: number }[];
  };
  purchased_count?: number;
}

export const createBlankCourse = async () => {
  return (
    await axios.post(`/courses/`, {
      title: '',
      description: '',
      benefits: [],
    })
  )?.data as {
    data: CourseInfo;
  };
};

export const getCourseDetail = async (courseId: string) => {
  return (await axios.get(`/courses/${courseId}`))?.data as {
    data: CourseInfo;
  };
};

export const updateCourse = async (courseId: string, params: any) => {
  return (await axios.patch(`/courses/${courseId}`, params))?.data as {
    data: CourseInfo;
  };
};

export const getCoursesCreator = async (lastId: string) => {
  return (await axios.get(`/courses/creator-courses?last_id=${lastId}`))
    ?.data as {
    data: {
      courses: CourseInfo[];
    };
  };
};

export const updateLecture = async (
  courseId: string,
  lectureId: string,
  params: any
) => {
  return (await axios.put(`/courses/${courseId}/lectures/${lectureId}`, params))
    ?.data as {
    data: LectureType;
  };
};

export const deleteLecture = async (courseId: string, lectureId: string) => {
  return await axios.delete(`/courses/${courseId}/lectures/${lectureId}`);
};

export const getLectureData = async (courseId: string, lectureId: string) => {
  return (await axios.get(`/courses/${courseId}/lectures/${lectureId}`))
    ?.data as {
    data: LectureType;
  };
};

export const getCoursesMasterData = async () => {
  return (await axios.get(`/courses/master-data`))?.data as {
    data: {
      course_prices: {
        web_amount: number;
        ios_amount: number;
        android_amount: number;
      }[];
      domains: TopicType[];
    };
  };
};

export const getCoursesList = async ({
  domainId,
  nextKey = '',
}: {
  domainId: number;
  nextKey: string;
}) => {
  let params = `?cursor_key=${nextKey}`;
  if (domainId && domainId > 0) {
    params = `${params}&domain_id=${domainId}`;
  }
  return (await axios.get(`/courses/home-timeline${params}`))?.data as {
    data: {
      courses: CourseInfo[];
      domains: TopicType[];
      next_key: string;
    };
  };
};

export const getPurchasedCoursesList = async (page: number) => {
  return (await axios.get(`/courses/purchased-courses?page=${page}`))?.data as {
    data: {
      courses: CourseInfo[];
    };
  };
};

export const answerAQuestion = async ({
  courseId,
  lectureId,
  questionId,
  answerId,
}: {
  courseId: string;
  lectureId: string;
  questionId: number;
  answerId: number;
}) => {
  return (
    await axios.post(
      `/courses/${courseId}/lectures/${lectureId}/user-answers`,
      {
        answer_id: answerId,
        question_id: questionId,
      }
    )
  )?.data as {
    data: LectureType;
  };
};

export const buyCourse = async (courseId: number) => {
  return await axios.post(`/payments/courses/credit`, {
    course_id: courseId,
  });
};

export interface CourseReviewItem {
  comment: string;
  created_at: string;
  id: number;
  rating: number;
  user: User;
}
export const getReviews = async ({
  courseId,
  lastId,
}: {
  courseId: number;
  lastId: number;
}) => {
  return (await axios.get(`/courses/${courseId}/reviews?last_id=${lastId}`))
    ?.data as {
    data: {
      review: CourseReviewItem;
      reviews: CourseReviewItem[];
    };
  };
};

export const addReview = async ({
  courseId,
  comment,
  rating,
}: {
  courseId: number;
  comment: string;
  rating: number;
}) => {
  return await axios.post(`/courses/${courseId}/reviews`, {
    comment: comment,
    rating: rating,
  });
};

export const updateLecturesPosition = async (
  courseId: string,
  ids: number[]
) => {
  return await axios.post(`/courses/${courseId}/lectures/positions`, {
    lecture_ids: ids,
  });
};

export const updateCourseActivate = async (
  courseId: number,
  newActive: boolean
) => {
  return await axios.post(`/courses/${courseId}/activate`, {
    is_active: newActive,
  });
};

export const increaseLectureViewCount = ({
  courseId,
  lectureId,
}: {
  courseId: number;
  lectureId: number;
}): void => {
  try {
    void axios
      .post(`/courses/${courseId}/lectures/${lectureId}/views`)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const getUserCoursesList = async (creatorId: number) => {
  const params = `?creator_id=${creatorId}`;
  return (await axios.get(`/courses/user-courses${params}`))?.data as {
    data: {
      courses: CourseInfo[];
    };
  };
};
