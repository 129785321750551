import classNames from 'classnames';
import { useMediaQuery } from 'common/utils';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useQuery } from 'react-query';
import { getPostTopics } from 'service/post_topic';
import useGlobalStore from 'store/global';
import { track } from '@amplitude/analytics-browser';
import styles from './GlobalPostTopicsList.module.scss';
import { TopicType } from 'service/user';
import Link from 'next/link';

const GlobalPostTopicsList = () => {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');
  const mobileTopicsRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { data } = useQuery(
    'getPostTopicsGlobal',
    async () => {
      return await getPostTopics();
    },
    {
      refetchOnWindowFocus: false,
      enabled: isAuthInit,
    }
  );

  const postTopics = useMemo(() => {
    const topics = data || [];
    if (!topics || topics.length === 0) return [];
    // -1 => LIVE topic's id
    return [
      ...topics.slice(0, 1),
      ...[
        { id: -1, name: 'LIVE' },
        { id: -2, name: '投資レベル' },
      ],
      ...topics.slice(1),
    ];
  }, [data]);

  if (router.pathname.startsWith('/livestream')) return <></>;
  if (router.pathname.startsWith('/account')) return <></>;
  if (router.pathname.startsWith('/company')) return <></>;
  if (router.pathname.startsWith('/dashboard')) return <></>;
  if (router.pathname === '/p/membership' && isSmallScreen) return <></>;
  return (
    <nav
      className={styles.postTopicLists}
      ref={mobileTopicsRef}
      aria-label="トピック一覧"
    >
      {postTopics.map((topic, index) => (
        <Link href={topicUrl(topic)} key={`post-topic-${index}`}>
          <a
            className={classNames(styles.item, {
              [styles.active]:
                topic.id === 0
                  ? router.pathname === '/'
                  : decodeURI(router.asPath).startsWith(topicUrl(topic)),
              [styles.trendItem]: topic.id === 0,
            })}
            style={topic.name?.length > 4 ? { minWidth: '130px' } : {}}
            onClick={() => {
              track('Click Topic Button', { topic_name: topic.name });
              if (!isSmallScreen) return;
              if (!mobileTopicsRef?.current) return;
              if (index > 3) {
                mobileTopicsRef.current.scrollLeft += 70;
              } else {
                mobileTopicsRef.current.scrollLeft -= 70;
              }
            }}
          >
            {topic.name}
          </a>
        </Link>
      ))}
    </nav>
  );
};

const topicUrl = (topic: TopicType) => {
  if (topic.id === 0) return '/';
  if (topic.id === -1) return '/post_topics/LIVE';
  if (topic.id === -2) return '/post_topics/investment_level';
  return `/post_topics/${topic.name}`;
};
export default GlobalPostTopicsList;
