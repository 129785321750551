import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';

import useStore from 'store/timeline';

import styles from './DashboardSidebar.module.scss';

export default function DashboardSidebar(): JSX.Element {
  const { pathname } = useRouter();
  const currentUser = useStore((state) => state.currentUser);
  const isHideDashboard = currentUser?.is_disabled_dashboard;

  if (!currentUser) return <></>;
  if (isHideDashboard) {
    return (
      <div className={styles.dashboardSidebarWrapper}>
        {currentUser?.has_live_permission && (
          <Link href="/dashboard/my_streams">
            <a>
              <div className={styles.navItem}>
                <div
                  className={cn(styles.headerWrapper, {
                    [styles.active]: pathname === '/dashboard/my_streams',
                  })}
                >
                  <div className={styles.headerText}>LIVE一覧</div>
                </div>
              </div>
            </a>
          </Link>
        )}
      </div>
    );
  }
  return (
    <div className={styles.dashboardSidebarWrapper}>
      <Link href="/dashboard">
        <a>
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathname === '/dashboard',
              })}
            >
              <div className={styles.headerText}>ダッシュボード</div>
            </div>
          </div>
        </a>
      </Link>
      {currentUser?.has_live_permission && (
        <Link href="/dashboard/my_streams">
          <a>
            <div className={styles.navItem}>
              <div
                className={cn(styles.headerWrapper, {
                  [styles.active]: pathname === '/dashboard/my_streams',
                })}
              >
                <div className={styles.headerText}>LIVE一覧</div>
              </div>
            </div>
          </a>
        </Link>
      )}
      <Link href="/dashboard/my_posts">
        <a>
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathname === '/dashboard/my_posts',
              })}
            >
              <div className={styles.headerText}>投稿履歴</div>
            </div>
          </div>
        </a>
      </Link>
      <Link href="/dashboard/my_comments">
        <a>
          <div className={styles.navItem}>
            <div
              className={cn(styles.headerWrapper, {
                [styles.active]: pathname === '/dashboard/my_comments',
              })}
            >
              <div className={styles.headerText}>コメント一覧</div>
            </div>
          </div>
        </a>
      </Link>
      {(currentUser?.is_creator || currentUser?.is_create_course_able) && (
        <Link href="/dashboard/my_courses">
          <a>
            <div className={styles.navItem}>
              <div
                className={cn(styles.headerWrapper, {
                  [styles.active]: pathname === '/dashboard/my_courses',
                })}
              >
                <div className={styles.headerText}>マイコース一覧</div>
              </div>
            </div>
          </a>
        </Link>
      )}
      {currentUser?.is_creator && (
        <>
          <Link href="/dashboard/my_subscribers">
            <a>
              <div className={styles.navItem}>
                <div
                  className={cn(styles.headerWrapper, {
                    [styles.active]: pathname === '/dashboard/my_subscribers',
                  })}
                >
                  <div className={styles.headerText}>プライム登録者一覧</div>
                </div>
              </div>
            </a>
          </Link>
          <Link href="/dashboard/my_plus_subscribers">
            <a>
              <div className={styles.navItem}>
                <div
                  className={cn(styles.headerWrapper, {
                    [styles.active]:
                      pathname === '/dashboard/my_plus_subscribers',
                  })}
                >
                  <div className={styles.headerText}>
                    プライムPlusの登録者一覧
                  </div>
                </div>
              </div>
            </a>
          </Link>
          <Link href="/dashboard/payment_details">
            <a>
              <div className={styles.navItem}>
                <div
                  className={cn(styles.headerWrapper, {
                    [styles.active]: pathname === '/dashboard/payment_details',
                  })}
                >
                  <div className={styles.headerText}>支払報告書一覧</div>
                </div>
              </div>
            </a>
          </Link>
        </>
      )}
    </div>
  );
}
