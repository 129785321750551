import { useInfiniteQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
// import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import Icon from 'components/common/Icon';
import InfiniteScroll from 'components/common/InfiniteScroll';

import styles from './CoursesList.module.scss';
import { PostsLoader } from 'components/common/PreloadContentLoader';
import { CourseInfo, getCoursesList } from 'service/course';
import CourseItemView from '../common/CourseItemView';
import classNames from 'classnames';
import { TopicType } from 'service/user';
export type Props = {
  disableInfiniteScroll?: boolean;
  noResultMessage: string;
  showCategory?: boolean;
};

const ALL_CATEGORY_ID = 0;
export default function CoursesList({
  disableInfiniteScroll,
  noResultMessage,
  showCategory = false,
}: Props): JSX.Element {
  const [isMounted, setIsMounted] = useState(false);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const [categories, setCategories] = useState<TopicType[]>([]);
  const [currentCategoryId, setCurrentCategoryId] = useState(ALL_CATEGORY_ID);
  useEffect(() => {
    // FIXME: this is a hack to prevent call api 2 times in first render
    const timeout = setTimeout(() => {
      setIsMounted(true);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const { isLoading, data, fetchNextPage, isFetchingNextPage, isFetched } =
    useInfiniteQuery(
      ['getCoursesList', currentCategoryId],
      async ({ pageParam = '1' }) => {
        if (!isAuthInit || !isMounted) return Promise.reject();
        return await getCoursesList({
          domainId: currentCategoryId,
          nextKey: pageParam as string,
        });
      },
      {
        getNextPageParam: (lastPage) => {
          const courses = lastPage?.data?.courses;
          if (courses && courses.length > 0 && lastPage?.data?.next_key) {
            return lastPage?.data?.next_key;
          }
        },
        onSuccess: (data) => {
          const domains = data?.pages[0]?.data?.domains || [];
          if (domains.length > 0 && domains.length !== categories.length) {
            setCategories(domains);
          }
        },
        // TODO: somehow enabled is not working as intended
        // useInfiniteQuery still runs when enabled is false
        // this is currently handled by only shows this component when isAuthInit is true
        enabled: isAuthInit && isMounted,
        refetchOnWindowFocus: false,
      }
    );

  const pages = data?.pages;
  const courses: CourseInfo[] = useMemo(
    () =>
      pages
        ? pages.reduce<CourseInfo[]>((curr, page) => {
            return curr.concat(page.data?.courses || []);
          }, [])
        : [],
    [pages]
  );

  let noPostPlaceholder;
  if (!isLoading && courses.length === 0) {
    noPostPlaceholder = (
      <div className={styles.noCourse}>
        <Icon name="empty" width={96} height={96} />
        <div dangerouslySetInnerHTML={{ __html: noResultMessage }}></div>
      </div>
    );
  }

  const coursesRender = (
    <>
      {courses.map((course, index) => {
        return (
          <CourseItemView course={course} key={`${course?.id}-${index}`} />
        );
      })}
    </>
  );

  return (
    <div className={styles.coursesListWrapper}>
      {showCategory && (
        <div className={styles.categories}>
          <span
            className={classNames(styles.categoryItem, {
              [styles.active]: currentCategoryId === ALL_CATEGORY_ID,
            })}
            onClick={() => setCurrentCategoryId(ALL_CATEGORY_ID)}
          >
            全て
          </span>
          {categories.map((category) => {
            return (
              <span
                key={`category-${category.id}`}
                className={classNames(styles.categoryItem, {
                  [styles.active]: currentCategoryId === category.id,
                })}
                onClick={() => setCurrentCategoryId(category.id)}
              >
                {category.name}
              </span>
            );
          })}
        </div>
      )}
      <InfiniteScroll
        fetchNextPage={fetchNextPage}
        disabled={disableInfiniteScroll}
        isLoading={isFetchingNextPage}
      >
        {isFetched ? coursesRender : <PostsLoader />}
      </InfiniteScroll>
      {noPostPlaceholder}
    </div>
  );
}
