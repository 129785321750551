import {
  ChapterType,
  CourseInfo,
  CourseStatus,
  LecturePaidType,
  LectureType,
  QuestionType,
} from 'service/course';
import { NewMedia } from 'service/media';
import { isNumber, jTrim, removeEmojis, secondsToHms, truncateStr } from '.';

export const mediaDuration = (media: NewMedia) => {
  if (!media) return '--:--';
  if (!media.duration || media.duration === 0) return '--:--';
  return secondsToHms(media.duration);
};

export const ratingNumberWithDecimal = (rating: number) => {
  if (isNumber(rating) === false) return '0.0';
  return rating.toFixed(1);
};

export const canWatchLectureVideo = (
  course: CourseInfo,
  lecture: LectureType
) => {
  if (course.is_owner || course.is_purchased) return true;
  return lecture.type === LecturePaidType.Free;
};

// export const lecturesErrors = (lectures: LectureType[]): string[] => {
//   if (!lectures || lectures.length < 3)
//     return ['最低3つのレクチャーを作成してください。'];
// };

export const isInValidQuestions = (questions: QuestionType[]) => {
  if (!questions || questions.length === 0) return false;
  return questions.some((question) => {
    if (!question.detail?.trim()) return true;
    if (question.answers.length !== 4) return true;
    if (question.answers.some((answer) => !answer.detail?.trim())) return true;
    return false;
  });
};

export const isInValidChapters = (chapters: ChapterType[]) => {
  if (!chapters || chapters.length === 0) return false;
  return chapters.some((chapter) => {
    if (!chapter.detail?.trim()) return true;
    return false;
  });
};

export const errorOfLecture = (lecture: LectureType): string => {
  if (!lecture) return 'エラーが発生しました';
  if (!lecture.title) {
    return 'レクチャーのタイトルを入力してください';
  }
  if (isInValidChapters(lecture.chapters)) {
    return 'チャプターの内容を正しく入力してください';
  }
  if (isInValidQuestions(lecture.questions)) {
    return 'クイズの内容を正しく入力してください';
  }
  return '';
};

export const courseStatusText = (status: CourseStatus) => {
  switch (status) {
    case CourseStatus.Draft:
      return '下書き';
    case CourseStatus.Rejected:
      return '拒否';
    case CourseStatus.Submitted:
      return '申請済み';
    case CourseStatus.Resubmit:
      return '差し戻し';
    case CourseStatus.Approved:
      return '承認済み';
    default:
      return '';
  }
};

const courseDetailsTitle = (courseInfo: CourseInfo) => {
  const user = courseInfo?.user;
  return `${courseInfo?.title ? `${courseInfo?.title} | ` : ''} ${
    user ? `${user?.name} (@${user?.username})  | ` : ''
  } PostPrime | 投資を楽しく学べる！`;
};

export const ogpDataOfCourse = (course: CourseInfo) => {
  if (!course) return { imageUrl: '', description: '', title: '' };
  return {
    imageUrl: course.thumbnail_image_media?.url || '',
    description:
      jTrim(removeEmojis(truncateStr(course.description, 150))) || '',
    title: jTrim(removeEmojis(courseDetailsTitle(course))),
  };
};
