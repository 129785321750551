import styles from './CourseItemView.module.scss';
import { CourseInfo } from 'service/course';
import React, { MouseEventHandler, useRef } from 'react';
import Image from 'next/image';
import UserInfo from 'components/common/UserInfo';
import Link from 'next/link';
import CourseRatingView from 'components/common/CourseRatingView';
import { useRouter } from 'next/router';
import dayjs from 'lib/dayjs';
import { track } from '@amplitude/analytics-browser';

function CourseItemView({ course }: { course: CourseInfo }): JSX.Element {
  const router = useRouter();
  const userInfoRef = useRef<HTMLDivElement>(null);
  const onCourseClick: MouseEventHandler = (e) => {
    const selection = window.getSelection();
    if (
      selection.type != 'Range' &&
      !userInfoRef?.current?.contains(e.target as HTMLElement)
    ) {
      track('View Course', {
        course_id: course.id?.toString(),
        course_name: course.title,
      });
      void router.push(`/courses/${course.id}`);
    }
  };

  return (
    <div className={styles.courseItemView}>
      <div className={styles.thumbnailWrapper}>
        {course.thumbnail_image_media?.url && (
          <Link href={`/courses/${course.id}`}>
            <a
              style={{ display: 'flex' }}
              onClick={() => {
                track('View Course', {
                  course_id: course.id?.toString(),
                  course_name: course.title,
                });
              }}
            >
              <Image
                src={course.thumbnail_image_media.url}
                height={course.thumbnail_image_media?.height}
                width={course.thumbnail_image_media?.width}
                alt="image"
              />
            </a>
          </Link>
        )}
      </div>
      <div className={styles.courseInfo} onClick={onCourseClick}>
        <div className={styles.courseInfoHeader}>
          <div ref={userInfoRef}>
            <UserInfo user={course.user} />
          </div>
          <span className={styles.timeSince}>
            {course.published_at &&
              dayjs(course.published_at).format('YYYY年MM月')}
          </span>
        </div>
        <div className={styles.courseInfoBody}>
          <div className={styles.courseTitle}>{course.title}</div>
          <div className={styles.ratingAndCategory}>
            {course.domains && course.domains.length > 0 && (
              <div className={styles.categoryItem}>
                {course.domains[0].name}
              </div>
            )}
            <CourseRatingView
              count={course.review_summary?.count}
              rating={course.review_summary?.rating}
              className={styles.ratingSection}
            />
          </div>
          <div className={styles.description}>{course.description}</div>
        </div>
      </div>
    </div>
  );
}

const MemoizedCourseItem = React.memo(CourseItemView);
export default MemoizedCourseItem;
