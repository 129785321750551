import classNames from 'classnames';
import { useMediaQuery } from 'common/utils';
import CopyrightInSidebar from 'components/CopyrightInSidebar';
import GlobalHeader from 'components/GlobalHeader';
import dynamic from 'next/dynamic';
import styles from './MainTemplate.module.scss';
const TimelineNav = dynamic(() => import('components/TimelineNav'), {
  ssr: false,
});
const UserSpotlightPC = dynamic(() => import('components/UserSpotlightPC'), {
  ssr: false,
});

export type Props = {
  children: React.ReactChild | React.ReactChild[];
  hasHeader?: boolean;
  hasRightSidebar?: boolean;
  mainSectionFitContent?: boolean;
};

export default function MainTemplate({
  children,
  hasRightSidebar = true,
  hasHeader = true,
  mainSectionFitContent = false,
}: Props): JSX.Element {
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1100px)');

  return (
    <div
      className={classNames(styles.mainTemplateWrapper, {
        [styles.notRightSidebar]: !hasRightSidebar,
        [styles.mainSectionFitContent]: mainSectionFitContent,
      })}
    >
      {hasHeader && <GlobalHeader />}
      <div className={styles.pageContent}>
        {!isMobileScreen && (
          <div className={styles.leftColumn}>
            <div className={styles.leftSticky}>
              <TimelineNav />
              <CopyrightInSidebar />
            </div>
          </div>
        )}
        <div className={styles.mainSection}>
          <div className={styles.content}>{children}</div>
        </div>
        {!isSmallScreen && hasRightSidebar && (
          <div className={styles.rightColumn}>
            <div className={styles.rightSticky}>
              <UserSpotlightPC />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
