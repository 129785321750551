import { COURSE_HELP_URL, useMediaQuery } from 'common/utils';
import styles from './CoursePageSection.module.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CoursePageTab } from 'common/interfaces/api';
import CoursesList from 'components/CoursesList';
import useGlobalStore from 'store/global';
import useStore from 'store/timeline';
import Button from 'components/common/Button';
import { useMutation } from 'react-query';
import { createBlankCourse } from 'service/course';
import PurchasedCoursesList from 'components/PurchasedCoursesList';
import LinkButton from 'components/common/LinkButton';
import classNames from 'classnames';

export type Props = {
  children: React.ReactChild | React.ReactChild[];
};

export default function CoursePageSection({ children }: Props): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(0);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const router = useRouter();
  const isCourseIndexPage = router.pathname === '/courses';
  const tab = router.query.tab as string;
  const showLogin = useGlobalStore((state) => state.showLogin);
  const currentUser = useStore((state) => state.currentUser);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  useEffect(() => {
    if (tab === CoursePageTab.AllCourses) {
      setSelectedTab(0);
    } else if (tab === CoursePageTab.MyCourses) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [tab]);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );

  const { mutate: createCourse, isLoading: isCreatingCourse } = useMutation(
    async () => {
      return await createBlankCourse();
    },
    {
      onSuccess: (data) => {
        setSnackbarMessage({
          type: 'success',
          text: '新しいコースを作成しました',
        });
        const newCourseId = data?.data?.id;
        if (newCourseId) {
          void router.push(`/dashboard/my_courses/${newCourseId}`);
        } else {
          setSnackbarMessage({
            type: 'error',
            text: 'エラーが発生しました',
          });
        }
      },
      onError: () => {
        setSnackbarMessage({
          type: 'error',
          text: 'エラーが発生しました',
        });
      },
    }
  );

  const createCourseClick = () => {
    if (!currentUser) {
      showLogin({});
      return;
    }
    createCourse();
  };

  const updateTabParam = useCallback(
    (i: number) => {
      let tab: string;
      if (i === 0) {
        tab = CoursePageTab.AllCourses;
      } else if (i === 1) {
        tab = CoursePageTab.MyCourses;
      }
      if (tab) {
        void router.push(
          {
            pathname: `/courses`,
            query: {
              tab: tab,
            },
          },
          `/courses?tab=${tab}`,
          { shallow: true }
        );
      }
    },
    [router]
  );

  const handleSelectTab = (i) => {
    updateTabParam(i);
  };
  return (
    <div className={styles.mainSectionWrapper}>
      <div className={styles.timeline}>
        {children}
        <div
          className={classNames(styles.coursePageWrapper, {
            [styles.hidden]: !isCourseIndexPage,
          })}
        >
          <Tabs
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedTabClassName={styles.selected}
            selectedIndex={selectedTab}
            onSelect={handleSelectTab}
          >
            <TabList className={styles.courseContentTabs}>
              <Tab className={styles.tab} id="allCourses">
                <div className={styles.tabContent}>全てのコース</div>
              </Tab>
              {currentUser && (
                <Tab className={styles.tab} id="myCourses">
                  <div className={styles.tabContent}>購入済みコース</div>
                </Tab>
              )}
              <div className={styles.addCourseSection}>
                {!isMobile && currentUser?.is_create_course_able && (
                  <>
                    <LinkButton
                      text="マイコース一覧"
                      onClick={() => {
                        void router.push(`/dashboard/my_courses`);
                      }}
                      marginRight={15}
                      fontSize={13}
                    />

                    <Button
                      text="コースを作成"
                      onClick={() => createCourseClick()}
                      isLoading={isCreatingCourse}
                      disabled={isCreatingCourse}
                    />
                  </>
                )}
                <a
                  href={COURSE_HELP_URL}
                  target="_blank"
                  className={styles.helpIcon}
                  rel="noopener"
                >
                  ?
                </a>
              </div>
            </TabList>
            <TabPanel>
              <div className={styles.tabContentWrapper}>
                {isAuthInit && (
                  <CoursesList
                    disableInfiniteScroll={false}
                    showCategory
                    noResultMessage="コースはまだありません"
                  />
                )}
              </div>
            </TabPanel>
            {currentUser && (
              <TabPanel>
                <div className={styles.tabContentWrapper}>
                  {isAuthInit && (
                    <PurchasedCoursesList
                      disableInfiniteScroll={false}
                      noResultMessage="現在、購入済みのコースはありません。<br/>気になるコースを購入して学びましょう！"
                    />
                  )}
                </div>
              </TabPanel>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
