import Dialog from '@material-ui/core/Dialog';
import { clone, isNumber, useMediaQuery } from 'common/utils';
import styles from './SymbolAlertSettingDialog.module.scss';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import LinkButton from 'components/common/LinkButton';
import useGlobalStore from 'store/global';
import { useState, useMemo } from 'react';
import {
  ChartSymbolAlertData,
  deleteAlertSetting,
  deleteAllAlertSettings,
  getAlertSettingV2,
  updateSymbolAlarm,
} from 'service/chart';
import {
  alertSettingTitle,
  enableOptions,
  isSupportIzanaviSymbol,
  isValidAlertSettings,
} from 'common/utils/chart';
import { useMutation, useQuery } from 'react-query';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { track } from '@amplitude/analytics-browser';
import {
  isGoldMember,
  isIzanaviMember,
  isPlatinumMember,
} from 'common/utils/membership';
import {
  MembershipPackageName,
  MembershipPackageRecurring,
} from 'common/interfaces/api';
import useStore from 'store/timeline';
import { ScreenName } from 'common/utils/pp_tracking';
export enum SymbolAlertPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

const SymbolAlertSettingDialog = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const showSymbolAlertSettingDialog = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialog
  );
  const showSymbolAlertSettingDialogInput = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialogInput
  );
  const currentUser = useStore((state) => state.currentUser);
  const [proSetting, setProSetting] = useState<ChartSymbolAlertData>(null);
  const [proSettingsCount, setProSettingsCount] = useState(0);
  const isGlobal = showSymbolAlertSettingDialogInput?.isGlobal;
  const symbolId = showSymbolAlertSettingDialogInput?.symbolId;
  const [data, setData] = useState<ChartSymbolAlertData>(
    {} as ChartSymbolAlertData
  );
  const [initData, setInitData] = useState<ChartSymbolAlertData>(
    {} as ChartSymbolAlertData
  );

  const [showingKeys, setShowingKeys] = useState<string[]>([]);
  const { isFetching } = useQuery(
    [
      'getAlertData',
      symbolId?.toString(),
      isGlobal,
      isPlatinumMember(currentUser),
    ],
    async () => {
      if (isGlobal || !symbolId || !showSymbolAlertSettingDialogInput?.open)
        return Promise.reject();
      return getAlertSettingV2(symbolId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const newSettingData = data?.data?.my_alarm;
        const proSetting = data?.data?.shared_alarms?.[0];
        setProSetting(proSetting);
        setData(newSettingData);
        setInitData(clone(newSettingData));
        setProSettingsCount(data?.data?.shared_alarms_total || 0);
        setShowingKeys(enableOptions(newSettingData));
      },
    }
  );

  const content = useMemo(() => {
    if (isGlobal) {
      return <AllSettings />;
    }
    if (isFetching) {
      return (
        <div className={styles.alertSettingWrapper}>
          <div className={styles.loadingOverlay}>
            <Icon name="loading-anim-3" width={48} height={48} />
          </div>
        </div>
      );
    }
    return (
      <AlertSettingSection
        showingKeys={showingKeys}
        setShowingKeys={setShowingKeys}
        data={data}
        setData={setData}
        proSetting={proSetting}
        proSettingsCount={proSettingsCount}
        initData={initData}
      />
    );
  }, [
    isGlobal,
    isFetching,
    showingKeys,
    data,
    proSetting,
    proSettingsCount,
    initData,
  ]);

  if (!showSymbolAlertSettingDialogInput?.open) return <></>;
  return (
    <Dialog
      open={!!showSymbolAlertSettingDialogInput?.open}
      onClose={() => showSymbolAlertSettingDialog(null)}
      fullScreen={isMobile}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: isMobile ? (isGlobal ? '15vh 0 15vh 0' : '5vh 0 5vh 0') : '',
        },
      }}
    >
      <div className={styles.dialogWrapper}>
        <div className={styles.closeBtn}>
          <Icon
            name="close"
            width={28}
            height={28}
            onClick={() => showSymbolAlertSettingDialog(null)}
          />
        </div>
        <div className={styles.header}>
          {symbolId && !isGlobal
            ? `${data?.symbol?.name ? `${data?.symbol?.name}の` : ''}`
            : 'チャート'}
          通知設定
        </div>
        {content}
      </div>
    </Dialog>
  );
};

const AlertSetting = ({
  period,
  type,
  data,
  setData,
}: {
  period: SymbolAlertPeriod;
  type: 'vote' | 'vote_change';
  data: ChartSymbolAlertData;
  setData: (data: ChartSymbolAlertData) => void;
}) => {
  const low = isNumber(data[`${type}_${period}_low`])
    ? data[`${type}_${period}_low`]
    : '';
  const high = isNumber(data[`${type}_${period}_high`])
    ? data[`${type}_${period}_high`]
    : '';
  const handleChangeNumber = (newValue: string, attrName: 'high' | 'low') => {
    const newData = clone(data) as ChartSymbolAlertData;
    let newVal = parseInt(newValue);
    if (type === 'vote') {
      newVal = Math.min(99, Math.max(newVal, 1));
    } else {
      newVal = Math.min(1000, Math.max(newVal, -100));
    }
    newData[`${type}_${period}_${attrName}`] = newVal;
    setData(newData);
  };

  let showError = false;
  if (type === 'vote') {
    showError = !!high && !!low && high <= low;
  } else {
    showError = isNumber(high) && isNumber(low) && high <= low;
  }
  return (
    <div className={styles.settingWrapper}>
      <div className={styles.settingTitle}>通知の条件 (%)</div>
      {showError && (
        <div className={styles.error}>
          上限の値は下限より大きい値を入力してください
        </div>
      )}
      <div className={styles.lowHighSetting}>
        <div className={styles.setNumber}>
          <input
            value={low}
            onChange={(e) => handleChangeNumber(e.target.value, 'low')}
            type="number"
            step={1}
          />
          <span>を下回る（下限）</span>
        </div>
        <div className={styles.setNumber}>
          <input
            value={high}
            onChange={(e) => handleChangeNumber(e.target.value, 'high')}
            type="number"
          />
          <span>を上回る（上限）</span>
        </div>
      </div>
    </div>
  );
};

const AllSettings = () => {
  const showSymbolAlertSettingDialog = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialog
  );
  const allSymbolAlertSettings =
    useGlobalStore((state) => state.allSymbolAlertSettings) || [];
  const refetchAlertSettings = useGlobalStore(
    (state) => state.refetchAlertSettings
  );
  const [openConfirmDeleteAll, setOpenConfirmDeleteAll] = useState(false);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const { mutate: deleteAlarm, isLoading: isDeletingOne } = useMutation(
    async ({ id }: { id: number }) => {
      return deleteAlertSetting(id);
    },
    {
      onSuccess: () => {
        void refetchAlertSettings();
        setSnackbarMessage({ type: 'success', text: '削除しました' });
      },
      onError: () => {
        setSnackbarMessage({ type: 'error', text: 'エラーが発生しました' });
      },
    }
  );

  const { mutate: deleteAllAlarms, isLoading: isDeletingAll } = useMutation(
    async () => {
      return deleteAllAlertSettings();
    },
    {
      onSuccess: () => {
        void refetchAlertSettings();
        setOpenConfirmDeleteAll(false);
        setSnackbarMessage({
          type: 'success',
          text: '全ての通知設定が削除されました',
        });
      },
      onError: () => {
        setSnackbarMessage({ type: 'error', text: 'エラーが発生しました' });
      },
    }
  );

  return (
    <div className={styles.settingList}>
      {allSymbolAlertSettings?.length === 0 && (
        <div className={styles.emptyWrapper}>
          <div className={styles.icon}>
            <Icon name="empty" width={48} height={48} />
          </div>
          <p className={styles.emptyText}>通知設定がありません</p>
        </div>
      )}
      <div className={styles.items}>
        {allSymbolAlertSettings?.map((setting, index) => {
          return (
            <div className={styles.item} key={`setting-${index}`}>
              <div className={styles.name}>{setting.symbol?.name}</div>
              <div className={styles.rightSide}>
                <span className={styles.icon}>
                  <Icon
                    name="edit-gray"
                    width={24}
                    height={24}
                    onClick={() => {
                      showSymbolAlertSettingDialog({
                        open: true,
                        isGlobal: false,
                        symbolId: setting.charts_symbols_id,
                      });
                    }}
                  />
                </span>
                <span
                  className={styles.icon}
                  style={isDeletingOne ? { pointerEvents: 'none' } : {}}
                >
                  <Icon
                    name="delete-trash"
                    width={24}
                    height={24}
                    onClick={() => {
                      if (isDeletingOne) return;
                      deleteAlarm({ id: setting.id });
                    }}
                  />
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {allSymbolAlertSettings && allSymbolAlertSettings.length > 0 && (
        <div className={styles.deleteAllSettingsSection}>
          <LinkButton
            text="全ての通知設定を削除"
            type="red"
            disabled={isDeletingAll}
            onClick={() => setOpenConfirmDeleteAll(true)}
          />
        </div>
      )}
      {openConfirmDeleteAll && (
        <ConfirmDialog
          open={openConfirmDeleteAll}
          title={`全ての通知設定を削除してもよろしいですか？`}
          yesText="削除"
          actionCloseFnc={() => {
            setOpenConfirmDeleteAll(false);
          }}
          actionSelectedFnc={() => deleteAllAlarms()}
        />
      )}
    </div>
  );
};

const AlertSettingSection = ({
  showingKeys,
  setShowingKeys,
  data,
  setData,
  proSetting,
  proSettingsCount,
  initData,
}: {
  showingKeys: string[];
  setShowingKeys: (a: string[]) => void;
  data: ChartSymbolAlertData;
  setData: (data: ChartSymbolAlertData) => void;
  proSetting: ChartSymbolAlertData;
  proSettingsCount: number;
  initData: ChartSymbolAlertData;
}) => {
  const [applyFavorite, setApplyFavorite] = useState(false);
  const showSymbolAlertSettingDialog = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialog
  );
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [isProcessing, setIsProcessing] = useState(false);
  const currentUser = useStore((state) => state.currentUser);
  const [openConfirmReplace, setOpenConfirmReplace] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const isCurrentAlarmExist = isNumber(data?.id) && data?.id !== 0;
  const refetchAlertSettings = useGlobalStore(
    (state) => state.refetchAlertSettings
  );
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );

  const { mutate: deleteAlarm } = useMutation(
    async ({ id }: { id: number }) => {
      return deleteAlertSetting(id);
    },
    {
      onSuccess: () => {
        setSnackbarMessage({ type: 'success', text: '削除しました' });
        setOpenConfirmDelete(false);
        void refetchAlertSettings();
        showSymbolAlertSettingDialog(null);
      },
      onError: () => {
        setSnackbarMessage({ type: 'error', text: 'エラーが発生しました' });
      },
    }
  );

  const { mutate: updateAlarm } = useMutation(
    async ({
      data,
      showingKeys,
      applyFavorite,
    }: {
      data: ChartSymbolAlertData;
      showingKeys: string[];
      applyFavorite: boolean;
    }) => {
      return updateSymbolAlarm({ data, showingKeys, applyFavorite });
    },
    {
      onMutate: () => {
        setIsProcessing(true);
      },
      onSuccess: () => {
        setTimeout(() => {
          setIsProcessing(false);
        }, 1000);
        track('Complete Setting Symbol Notification', {
          market: data?.symbol?.name,
        });
        setSnackbarMessage({
          type: 'success',
          text: '通知の設定が完了しました',
        });
        refetchAlertSettings();
      },
      onError: () => {
        setIsProcessing(false);
        setSnackbarMessage({ type: 'error', text: 'エラーが発生しました' });
      },
    }
  );

  const saveOnlyIzanaviSetting = () => {
    if (!isIzanaviMember(currentUser)) return;
    if (!data?.izanavi_signal) return;
    const newDataSetting = clone(initData) as ChartSymbolAlertData;
    newDataSetting.izanavi_signal = data.izanavi_signal;
    updateAlarm({
      data: newDataSetting,
      showingKeys: enableOptions(newDataSetting),
      applyFavorite: false,
    });
  };

  const handleReplaceSetting = () => {
    if (!proSetting) return;
    const newDataSetting = clone(proSetting) as ChartSymbolAlertData;
    newDataSetting.id = data?.id;
    newDataSetting.user_id = data?.user_id;
    // pro setting not support izanavi_signal
    newDataSetting.izanavi_signal = !!data?.izanavi_signal;
    const newShowingKeys = enableOptions(newDataSetting);
    setData(newDataSetting);
    setShowingKeys(newShowingKeys);
    updateAlarm({
      data: newDataSetting,
      showingKeys: newShowingKeys,
      applyFavorite: false,
    });
  };
  return (
    <>
      <div className={styles.settingSection}>
        {isSupportIzanaviSymbol(data?.symbol) && (
          <div className={styles.settings}>
            <div className={styles.settingsTitle}>
              IZANAVIのAI通知
              <a
                target="_blank"
                href="https://help.postprime.com/hc/ja/articles/10073223351311#h_01J1E4QQHMQFRZE2VXS900H3YC"
              >
                <span className={styles.helpIcon}>?</span>
              </a>
            </div>
            <div className={styles.setting}>
              <div
                className={styles.checkboxWrapper}
                onClick={() => {
                  if (!data?.izanavi_signal && !isIzanaviMember(currentUser)) {
                    setShowMembershipPayDialog({
                      open: true,
                      initPackage: MembershipPackageName.Platinum,
                      initRecurring: MembershipPackageRecurring.Year,
                      screen: ScreenName.SymbolAlertSetting,
                    });
                    setSnackbarMessage({
                      type: 'warning',
                      text: 'この機能が使えるメンバーシップに登録しよう！',
                      autoHideDuration: 10000,
                    });
                    return;
                  }
                  const newData = clone(data) as ChartSymbolAlertData;
                  newData.izanavi_signal = !newData.izanavi_signal;
                  setData(newData);
                }}
              >
                <input
                  type="checkbox"
                  className={styles.settingCheckbox}
                  name="izanavi-notification"
                  id="izanavi-notification"
                  readOnly
                  checked={!!data?.izanavi_signal}
                />
                <span>
                  <strong>このマーケットのAI通知を受け取る</strong>
                </span>
              </div>
            </div>
            {isIzanaviMember(currentUser) && !isGoldMember(currentUser) && (
              <div className={styles.izanaviSettingButton}>
                <Button
                  text="IZANAVIのAI通知を設定する"
                  disabled={isProcessing || !data?.izanavi_signal}
                  isLoading={isProcessing}
                  onClick={saveOnlyIzanaviSetting}
                  rounded
                />
              </div>
            )}
          </div>
        )}
        <div className={styles.settings}>
          <div className={styles.settingsTitle}>
            投票率・中央値チャートの通知
            <a
              href="https://help.postprime.com/hc/ja/articles/4859529332751#h_01GR5398SQHMS2V4MAJRVF0184"
              target="_blank"
            >
              <span className={styles.helpIcon}>?</span>
            </a>
          </div>
          {proSettingsCount > 0 && (
            <div className={styles.proSettingActionBtn}>
              <Button
                text="自動でおすすめトレーダーの設定をする"
                disabled={isProcessing}
                rounded
                onClick={() => {
                  track('Copy Pro Setting Notification', {
                    market: data?.symbol?.name,
                  });
                  if (!isPlatinumMember(currentUser)) {
                    setShowMembershipPayDialog({
                      open: true,
                      showMembershipStatus: false,
                      initPackage: MembershipPackageName.Platinum,
                      screen: ScreenName.SymbolAlertSetting,
                    });
                    setSnackbarMessage({
                      type: 'warning',
                      text: 'Platinumメンバーシップに登録して特別な機能を体験しよう！',
                      autoHideDuration: 10000,
                    });
                    return;
                  }
                  if (
                    !proSetting ||
                    proSetting.charts_symbols_id !== data?.charts_symbols_id
                  )
                    return;
                  if (isCurrentAlarmExist) {
                    setOpenConfirmReplace(true);
                  } else {
                    handleReplaceSetting();
                  }
                }}
              />
            </div>
          )}
          {Object.keys(SymbolAlertPeriod).map((key, index) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const period = SymbolAlertPeriod[key] as string;
            const keyName = `vote_${period}`;
            const showingSetting = showingKeys.includes(keyName);
            return (
              <div className={styles.setting} key={index}>
                <div
                  className={styles.checkboxWrapper}
                  onClick={() => {
                    const newKeys = clone(showingKeys) as string[];
                    const idx = newKeys.indexOf(keyName);
                    if (idx >= 0) {
                      newKeys.splice(idx, 1);
                    } else {
                      newKeys.push(keyName);
                    }
                    setShowingKeys(newKeys);
                  }}
                >
                  <input
                    type="checkbox"
                    className={styles.settingCheckbox}
                    name={key}
                    id={key}
                    readOnly
                    checked={showingSetting}
                  />
                  <span>{alertSettingTitle(period as SymbolAlertPeriod)}</span>
                </div>
                {showingSetting && (
                  <AlertSetting
                    period={period as SymbolAlertPeriod}
                    type="vote"
                    data={data}
                    setData={setData}
                  />
                )}
              </div>
            );
          })}
          <div className={styles.setting}>
            <div
              className={styles.checkboxWrapper}
              onClick={() => {
                const newData = clone(data) as ChartSymbolAlertData;
                newData.prediction_week_gt_month =
                  !newData.prediction_week_gt_month;
                setData(newData);
              }}
            >
              <input
                type="checkbox"
                className={styles.settingCheckbox}
                name="prediction_week_gt_month"
                id="prediction_week_gt_month"
                readOnly
                checked={data.prediction_week_gt_month}
              />
              <span>中央値チャートが「強欲」の通知</span>
            </div>
          </div>
          <div className={styles.setting}>
            <div
              className={styles.checkboxWrapper}
              onClick={() => {
                const newData = clone(data) as ChartSymbolAlertData;
                newData.prediction_month_gt_week =
                  !newData.prediction_month_gt_week;
                setData(newData);
              }}
            >
              <input
                type="checkbox"
                className={styles.settingCheckbox}
                name="prediction_month_gt_week"
                id="prediction_month_gt_week"
                readOnly
                checked={data.prediction_month_gt_week}
              />
              <span>中央値チャートが「恐怖」の通知</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.effectAllCheckbox}
        onClick={() => {
          setApplyFavorite(!applyFavorite);
        }}
      >
        <input
          type="checkbox"
          className={styles.settingCheckbox}
          readOnly
          checked={applyFavorite}
        />
        <span>同じ条件で現在のウォッチリスト全ての通知を受け取る</span>
      </div>
      <div className={styles.buttonGroup}>
        <div className={styles.settingBtn}>
          <Button
            text="通知を設定"
            onClick={() => {
              if (!isGoldMember(currentUser)) {
                setShowMembershipPayDialog({
                  open: true,
                  showMembershipStatus: false,
                  initPackage: MembershipPackageName.Platinum,
                  screen: ScreenName.HomeTimeline,
                });
                setSnackbarMessage({
                  type: 'warning',
                  text: 'Platinumメンバーシップに登録して特別な機能を体験しよう！',
                  autoHideDuration: 10000,
                });
                return;
              }
              updateAlarm({ data, showingKeys, applyFavorite });
            }}
            isLoading={isProcessing}
            disabled={!isValidAlertSettings(data, showingKeys)}
          />
        </div>
        {isGoldMember(currentUser) && (
          <div className={styles.linkBtn}>
            <LinkButton
              text="設定済みの通知を確認"
              onClick={() =>
                showSymbolAlertSettingDialog({ open: true, isGlobal: true })
              }
            />
          </div>
        )}
        {(isGoldMember(currentUser) || isIzanaviMember(currentUser)) &&
          isCurrentAlarmExist && (
            <div className={styles.linkBtn}>
              <LinkButton
                text="この通知設定を削除"
                type="red"
                onClick={() => setOpenConfirmDelete(true)}
              />
            </div>
          )}
      </div>
      {openConfirmDelete && (
        <ConfirmDialog
          open={openConfirmDelete}
          title={`${data?.symbol?.name}の通知設定を削除してもよろしいですか？`}
          yesText="削除"
          actionCloseFnc={() => {
            setOpenConfirmDelete(false);
          }}
          actionSelectedFnc={() => deleteAlarm({ id: data?.id })}
        />
      )}
      {openConfirmReplace && (
        <ConfirmDialog
          open={openConfirmReplace}
          title={`既に入力済みの内容を書き換えます。${
            isMobile ? '<br />' : ''
          }よろしいですか？`}
          yesText="自動入力する"
          noText="やめる"
          actionCloseFnc={() => {
            setOpenConfirmReplace(false);
          }}
          actionSelectedFnc={() => {
            setOpenConfirmReplace(false);
            handleReplaceSetting();
          }}
        />
      )}
    </>
  );
};
export default SymbolAlertSettingDialog;
