import ContentLoader from 'react-content-loader';
import styles from './PreloadContentLoader.module.scss';

export const SmallPostLoader = (props) => (
  <ContentLoader
    speed={1.5}
    width={300}
    height={159}
    viewBox="0 0 300 159"
    uniqueKey="small-post-loader"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="33" cy="33" r="17" />
    <rect x="58" y="12" rx="2" ry="2" width="232" height="11" />
    <rect x="58" y="30" rx="2" ry="2" width="231" height="10" />
    <rect x="207" y="47" rx="2" ry="2" width="82" height="82" />
    <rect x="21" y="63" rx="0" ry="0" width="171" height="8" />
    <rect x="20" y="83" rx="0" ry="0" width="176" height="7" />
    <rect x="21" y="102" rx="0" ry="0" width="174" height="7" />
    <rect x="228" y="76" rx="0" ry="0" width="1" height="0" />
    <rect x="16" y="142" rx="0" ry="0" width="269" height="12" />
    <rect x="20" y="122" rx="0" ry="0" width="172" height="8" />
  </ContentLoader>
);

export const BigThumbSmallPostLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={94}
    viewBox="0 0 400 94"
    backgroundColor="#f3f3f3"
    uniqueKey="big-thumb-small-post-loader"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="2" y="0" rx="0" ry="0" width="167" height="92" />
    <rect x="185" y="3" rx="0" ry="0" width="106" height="18" />
    <rect x="184" y="28" rx="0" ry="0" width="87" height="20" />
    <rect x="304" y="6" rx="0" ry="0" width="144" height="16" />
    <rect x="277" y="29" rx="0" ry="0" width="130" height="19" />
    <rect x="184" y="56" rx="0" ry="0" width="212" height="18" />
    <rect x="184" y="79" rx="0" ry="0" width="83" height="18" />
  </ContentLoader>
);

export const PostLoader = (props) => (
  <ContentLoader
    speed={1.5}
    width={672}
    height={581}
    uniqueKey="post-loader"
    viewBox={`0 0 672 581`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="36" cy="374" r="32" />
    <rect x="0" y="8" rx="0" ry="0" width="672" height="324" />
    <rect x="44" y="128" rx="0" ry="0" width="50" height="0" />
    <rect x="93" y="352" rx="0" ry="0" width="242" height="21" />
    <rect x="91" y="381" rx="0" ry="0" width="245" height="18" />
    <circle cx="672" cy="373" r="3" />
    <rect x="0" y="428" rx="0" ry="0" width="672" height="17" />
    <rect x="0" y="457" rx="0" ry="0" width="672" height="18" />
    <rect x="0" y="490" rx="0" ry="0" width="672" height="16" />
    <rect x="0" y="524" rx="0" ry="0" width="672" height="29" />
    <circle cx="644" cy="377" r="17" />
  </ContentLoader>
);

export const UserInfoLoader = () => {
  return (
    <ContentLoader
      speed={1.5}
      width={220}
      uniqueKey="user-info-loader"
      height={50}
      viewBox="0 0 220 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="261" y="34" rx="0" ry="0" width="0" height="1" />
      <rect x="51" y="16" rx="0" ry="0" width="233" height="22" />
      <circle cx="23" cy="26" r="20" />
    </ContentLoader>
  );
};

export const UserInfosLoader = () => {
  return (
    <div className={styles.userInfoLoader}>
      {Array.from(Array(10).keys()).map((index) => {
        return <UserInfoLoader key={index} />;
      })}
    </div>
  );
};

export const PostsLoader = () => {
  return (
    <div className={styles.loader}>
      {Array.from(Array(5).keys()).map((index) => {
        return <PostLoader key={index} />;
      })}
    </div>
  );
};

export const BigThumbSmallPostsLoader = () => {
  return (
    <div className={styles.loader}>
      {Array.from(Array(10).keys()).map((index) => {
        return (
          <BigThumbSmallPostLoader
            key={index}
            style={{ marginBottom: '8px' }}
          />
        );
      })}
    </div>
  );
};

export const TrendingTopicKeywordLoader = () => {
  return (
    <ContentLoader
      speed={1.5}
      width={300}
      height={50}
      uniqueKey="trending-topic-loader"
      viewBox="0 0 300 50"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="6" y="15" rx="0" ry="0" width="201" height="25" />
      <rect x="261" y="34" rx="0" ry="0" width="0" height="1" />
      <rect x="212" y="18" rx="0" ry="0" width="82" height="19" />
    </ContentLoader>
  );
};

export const TrendingTopicKeywordsLoader = () => {
  return (
    <div className={styles.trendingTopicKwLoader}>
      {Array.from(Array(20).keys()).map((index) => {
        return (
          <div className={styles.topic} key={index}>
            <TrendingTopicKeywordLoader key={index} />
          </div>
        );
      })}
    </div>
  );
};
