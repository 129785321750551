import Dialog from '@material-ui/core/Dialog';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';
import { track } from '@amplitude/analytics-browser';
import cn from 'classnames';
import { firebaseAuth } from 'lib/firebase';
import {
  fetchSignInMethodsForEmail,
  AuthError,
  getIdTokenResult,
  ParsedToken,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  // TwitterAuthProvider,
  signInWithEmailAndPassword,
  AuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import Icon from 'components/common/Icon';
import useGlobalStore from 'store/global';

import styles from './LoginDialog.module.scss';
import debounce from 'common/utils/debounce';
import { useMutation } from 'react-query';
import {
  isProduction,
  POLICY_URL,
  USER_TERM_URL,
  validEmail,
  validPassword,
  validPasswordConfirm,
} from 'common/utils';
import ForgotPassDialog from './ForgotPassDialog';
import { useRouter } from 'next/router';
import { parse } from 'cookie';
import { generateTrackingParam } from 'common/utils/trackingTag';
import { createAccountEvent, openLoginDialogEvent } from 'common/utils/user';

enum Mode {
  INITIAL,
  LOGIN,
  REGISTER,
}

const validName = (name: string) => {
  return name && name.trim().length <= 15;
};

interface NewUserParamType {
  name: string;
  avatar: string;
  email: string;
  uid: string;
}

export default function LoginDialog(): JSX.Element {
  const showLoginInput = useGlobalStore((state) => state.showLoginInput);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const [showingTitle, setShowingTitle] = useState<'login' | 'signup'>(
    showLoginInput?.showingTitle || 'login'
  );
  const router = useRouter();
  const firebaseUser = useGlobalStore((state) => state.firebaseUser);
  const [processing, setProcessing] = useState(false);
  const [mode, setMode] = useState(Mode.INITIAL);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const [newAccountParam, setNewAccountParam] = useState<NewUserParamType>();
  // const nameRef = useRef<string>();
  const [hasUserAction, setHasUserAction] = useState(false);
  // Error
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordConfirmError, setPasswordConfirmError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [isInputValid, setInputValid] = useState(false);
  useEffect(() => {
    if (showLoginInput) {
      setShowingTitle(showLoginInput?.showingTitle || 'login');
    }
  }, [showLoginInput]);

  // Only run once.
  useEffect(() => {
    if (!showLoginInput) return;
    try {
      openLoginDialogEvent(router.pathname);
      track('Show Login Popup');
      // eslint-disable-next-line no-empty
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoginInput]);

  // Validate email
  useEffect(() => {
    if (mode !== Mode.INITIAL && email && !validEmail(email)) {
      setEmailError('無効のメールアドレス');
    } else {
      setEmailError('');
    }
  }, [mode, email]);

  // Validate password
  useEffect(() => {
    if (password && !validPassword(password)) {
      if (mode === Mode.REGISTER) {
        setPasswordError('8文字以上の半角英数字で入力してください');
      }
    } else {
      setPasswordError('');
    }
  }, [password, mode]);

  // Validate confirm password
  useEffect(() => {
    if (password && !validPasswordConfirm(password, passwordConfirm)) {
      setPasswordConfirmError('パスワードが一致しません');
    } else {
      setPasswordConfirmError('');
    }
  }, [password, passwordConfirm]);

  // Validate name
  useEffect(() => {
    if (name && !validName(name)) {
      setNameError('15文字以内で入力してください');
    } else {
      setNameError('');
    }
  }, [name]);

  useEffect(() => {
    if (mode === Mode.INITIAL && !validEmail(email)) {
      setInputValid(false);
    } else if (
      mode === Mode.LOGIN &&
      (!email || emailError || !password || passwordError)
    ) {
      setInputValid(false);
    } else if (
      mode === Mode.REGISTER &&
      (!email ||
        emailError ||
        !name ||
        nameError ||
        !password ||
        passwordError ||
        !passwordConfirm ||
        passwordConfirmError)
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [
    mode,
    email,
    emailError,
    password,
    passwordError,
    name,
    nameError,
    passwordConfirm,
    passwordConfirmError,
  ]);

  const handleClose = () => {
    showLogin(null);
  };

  // reset all fields when closed
  useEffect(() => {
    // delay fields reset a bit so that it happens after the dialog is fully closed
    if (showLoginInput) return;
    const timeout = setTimeout(() => {
      setEmail('');
      setPassword('');
      setName('');
      setMode(Mode.INITIAL);
    }, 200);
    return () => timeout && clearTimeout(timeout);
  }, [showLoginInput]);

  const { isLoading: isCheckingEmail, mutate: checkEmail } = useMutation(
    (email: string) => {
      return fetchSignInMethodsForEmail(firebaseAuth, email);
    },
    {
      onSuccess: (res) => {
        if (res.length === 0) {
          setMode(Mode.REGISTER);
        } else {
          // res is a array of login provider. example: ['password', 'google.com']
          if (!res.includes('password')) {
            const platform = providerName(res[0]);
            setEmailError(
              `既に${platform}アカウントで利用中です。</br>${platform}で続けるからログインください。`
            );
          } else {
            setMode(Mode.LOGIN);
          }
        }
      },
      onError: (error: AuthError) => {
        if (error.code === 'auth/invalid-email') {
          setEmailError('無効のメールアドレス');
        } else {
          setEmailError('エラーが発生しました');
        }
      },
    }
  );

  const { mutate: createPostPrimeAccount } = useMutation(
    async ({
      avatar,
      name,
      email,
    }: {
      avatar: string;
      name: string;
      email: string;
    }) => {
      const cookie = parse(document?.cookie);
      const trackingParam = generateTrackingParam(cookie);
      const { utm_source, utm_medium, utm_campaign } = router.query;
      const source = (utm_source as string) || trackingParam.utm_source || '';
      const campaign =
        (utm_campaign as string) || trackingParam.utm_campaign || '';
      const medium = (utm_medium as string) || trackingParam.utm_medium || '';
      return await axios.post('/users/', {
        avatar,
        name: name || 'PostPrime User',
        email,
        ...((utm_source ||
          utm_medium ||
          utm_campaign ||
          trackingParam.utm_source) && {
          source: {
            utm_source: source,
            utm_medium: medium,
            utm_campaign: campaign,
          },
        }),
      });
    },
    {
      onSuccess: async () => {
        await firebaseUser.getIdToken(true);
        createAccountEvent(router.pathname);
        track('Complete Registration');
        setSnackbarMessage({
          text: '新規アカウントが作成されました',
          type: 'success',
        });
        setTimeout(() => {
          void firebaseUser.getIdToken(true);
        }, 1000);
        setTimeout(() => {
          void firebaseUser.getIdToken(true);
        }, 2000);
        if (router.pathname === '/lp') {
          void router.push('/');
        }
        setProcessing(false);
        showLogin(null);
      },
      onError: () => {
        setSnackbarMessage({ text: 'エラーが発生しました', type: 'error' });
      },
    }
  );

  const debouncedCheckEmail = useMemo(
    () => debounce(checkEmail, 500),
    [checkEmail]
  );

  useEffect(() => {
    if (firebaseUser) {
      const createUser = async ({
        name,
        email,
        avatar,
      }: {
        name?: string;
        email: string;
        avatar?: string;
      }) => {
        const idTokenResult = await getIdTokenResult(firebaseUser);
        const claims = idTokenResult.claims as ParsedToken & {
          yp_user_id: number;
        };
        const isNewUser = !claims.yp_user_id;
        if (isNewUser) {
          try {
            if (firebaseUser.providerData[0].providerId === 'password' && !name)
              return;
            setNewAccountParam({
              uid: firebaseUser.uid,
              email: email,
              name: name,
              avatar: avatar,
            });
          } catch (error) {
            console.error('Error creating user!');
            setSnackbarMessage({
              type: 'error',
              text: '新規ユーザー作成が失敗しました',
              autoHideDuration: 6000,
            });
            checkEmail(email);
            if (axios.isAxiosError(error)) {
              if (error?.response) {
                console.error(error.response.data);
              } else {
                console.error(error);
              }
            }
          }
        } else {
          showLogin(null);
        }
      };
      void createUser({
        email: firebaseUser.email,
        name: name || firebaseUser.displayName?.slice(0, 15),
        avatar: firebaseUser.photoURL,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUser]);

  useEffect(() => {
    if (newAccountParam?.uid && hasUserAction) {
      createPostPrimeAccount({
        name: newAccountParam.name,
        avatar: newAccountParam.avatar,
        email: newAccountParam.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAccountParam?.uid]);

  const facebookProvider = new FacebookAuthProvider();
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  // const twitterProvider = new TwitterAuthProvider();

  const handleAuthError = (
    e: { code?: string; email?: string },
    errorText: string
  ) => {
    console.error(e);
    if (e?.code === 'auth/account-exists-with-different-credential') {
      errorText = `既に他のソーシャルアカウントで${
        e?.email ? `「${e?.email}」` : '該当メールアドレス'
      }を利用してログインしました。このメールアドレスに関連付けられているプロバイダーを使用してサインインしてください。`;
    }
    setSnackbarMessage({
      type: 'error',
      text: errorText,
      autoHideDuration: 6000,
    });
  };

  const handleEmailLogin = async () => {
    setProcessing(true);
    setHasUserAction(true);
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
      if (router.pathname === '/lp') {
        void router.push('/');
      }
    } catch (e) {
      handleAuthError(e, 'ログインに失敗しました');
    } finally {
      setProcessing(false);
    }
  };

  const handleEmailRegister = async () => {
    setProcessing(true);
    setHasUserAction(true);
    try {
      // Create new user
      await createUserWithEmailAndPassword(firebaseAuth, email, password);
      // Sign in with created user
    } catch (e) {
      handleAuthError(e, 'アカウント登録に失敗しました');
    }
  };

  const handleLoginWithProvider = async (provider: AuthProvider) => {
    try {
      setHasUserAction(true);
      await signInWithPopup(firebaseAuth, provider);
      if (router.pathname === '/lp') {
        void router.push('/');
      }
    } catch (e) {
      handleAuthError(e, 'ログインに失敗しました');
    }
  };

  const handleFacebookLogin = () => {
    void handleLoginWithProvider(facebookProvider);
  };

  const handleGoogleLogin = () => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });
    void handleLoginWithProvider(googleProvider);
  };

  const handleAppleLogin = () => {
    void handleLoginWithProvider(appleProvider);
  };

  // const handleTwitterLogin = () => {
  //   void handleLoginWithProvider(twitterProvider);
  // };

  const handlePasswordKeydown: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      void handleEmailLogin();
    }
  };

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
    if (mode !== Mode.INITIAL) {
      debouncedCheckEmail(e.target.value);
    }
  };

  return (
    <Dialog
      open={!!showLoginInput}
      onClose={handleClose}
      scroll="body"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <form className={styles.dialogWrapper}>
        <div className={styles.closeBtn}>
          <Icon name="close" width={28} height={28} onClick={handleClose} />
        </div>
        <div className={styles.tabList}>
          <div
            className={cn(styles.tab, {
              [styles.selected]:
                (showingTitle === 'login' && mode === Mode.INITIAL) ||
                mode === Mode.LOGIN,
            })}
            onClick={() => setShowingTitle('login')}
          >
            <div className={styles.tabContent}>ログイン</div>
          </div>
          <div
            className={cn(styles.tab, {
              [styles.selected]:
                (showingTitle === 'signup' && mode === Mode.INITIAL) ||
                mode === Mode.REGISTER,
            })}
            onClick={() => setShowingTitle('signup')}
          >
            <div className={styles.tabContent}>新規作成</div>
          </div>
        </div>
        <div
          className={cn(styles.loginBtn, styles.apple)}
          onClick={handleAppleLogin}
        >
          <div className={styles.logo}>
            <Icon name="apple-black" width={20} height={24} />
          </div>
          Appleで続ける
        </div>

        <div
          className={cn(styles.loginBtn, styles.facebook)}
          onClick={handleFacebookLogin}
        >
          <div className={styles.logo}>
            <Icon name="facebook-blue" width={14} height={26} />
          </div>
          Facebookで続ける
        </div>
        <div
          className={cn(styles.loginBtn, styles.google)}
          onClick={handleGoogleLogin}
        >
          <div className={styles.logo}>
            <Icon name="google" width={44} height={44} />
          </div>
          Googleで続ける
        </div>
        {/* {showingTitle !== 'signup' && (
          <div
            className={cn(styles.loginBtn, styles.twitter)}
            onClick={handleTwitterLogin}
          >
            <div className={styles.logo}>
              <Icon name="twitter-blue" width={21} height={17} />
            </div>
            Twitterで続ける
          </div>
        )} */}
        <div className={styles.dividerGroup}>
          <div className={styles.divider} />
          <div className={styles.dividerText}>または</div>
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label} htmlFor="email-login-email">
            メールアドレス
          </label>
          <div className={styles.emailWrapper}>
            <input
              type="email"
              id="email-login-email"
              name="email-login-email"
              placeholder="（例）tarosuzuki@gmail.com"
              value={email}
              onChange={handleEmailChange}
              autoComplete="email"
              disabled={processing}
            />
            <div className={styles.checking}>
              {isCheckingEmail && (
                <Icon name="loading-anim-3" width={24} height={24} />
              )}
            </div>
          </div>
          <div
            className={styles.error}
            dangerouslySetInnerHTML={{ __html: emailError }}
          ></div>
        </div>
        {mode === Mode.INITIAL && (
          <button
            className={cn(styles.loginBtn, styles.normal)}
            onClick={(e) => {
              e.preventDefault();
              checkEmail(email);
            }}
            disabled={!isInputValid || processing}
          >
            {processing ? (
              <Icon name="loading-anim-2" width={24} height={24} />
            ) : (
              'メールアドレスで続ける'
            )}
          </button>
        )}
        {mode === Mode.LOGIN && (
          <>
            <div className={styles.inputGroup}>
              <label className={styles.label} htmlFor="email-login-password">
                パスワード
              </label>
              <input
                type="password"
                id="email-login-password"
                name="email-login-password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handlePasswordKeydown}
                disabled={processing}
              />
              <div className={styles.error}>{passwordError}</div>
            </div>
            <button
              className={cn(styles.loginBtn, styles.normal)}
              onClick={handleEmailLogin}
              type="button"
              disabled={!isInputValid || processing}
            >
              {processing ? (
                <Icon name="loading-anim-2" width={24} height={24} />
              ) : (
                'ログイン'
              )}
            </button>
            <a
              className={styles.forgetPass}
              onClick={() => setOpenForgotPass(true)}
            >
              パスワードを忘れた方
            </a>
            {openForgotPass && (
              <ForgotPassDialog
                open={openForgotPass}
                handleClose={() => setOpenForgotPass(false)}
              />
            )}
          </>
        )}
        {mode === Mode.REGISTER && (
          <>
            <div className={styles.inputGroup}>
              <label className={styles.label} htmlFor="email-register-name">
                名前
              </label>
              <input
                type="text"
                id="email-register-name"
                name="email-register-name"
                autoComplete="current-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="例）高橋ダン"
                disabled={processing}
              />
              <div className={styles.error}>{nameError}</div>
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label} htmlFor="email-register-password">
                パスワード
              </label>
              <input
                type="password"
                id="email-register-password"
                name="email-register-password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={processing}
              />
              <div className={styles.error}>{passwordError}</div>
            </div>
            <div className={styles.inputGroup}>
              <label
                className={styles.label}
                htmlFor="email-register-password-confirm"
              >
                確認用のパスワード
              </label>
              <input
                type="password"
                id="email-register-password-confirm"
                name="email-register-password-confirm"
                autoComplete="new-password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                disabled={processing}
              />
              <div className={styles.error}>{passwordConfirmError}</div>
            </div>
            <button
              className={cn(styles.loginBtn, styles.normal)}
              onClick={handleEmailRegister}
              type="button"
              disabled={!isInputValid || processing}
            >
              {processing ? (
                <Icon name="loading-anim-2" width={24} height={24} />
              ) : (
                'アカウント作成してホームへ'
              )}
            </button>
            <div className={styles.policy}>
              アカウントを作成することで、
              <br />
              <a href={USER_TERM_URL} target="_blank">
                利用規約
              </a>
              と
              <a href={POLICY_URL} target="_blank">
                プライバシーポリシー
              </a>
              に
              <br />
              同意するものとします。
            </div>
          </>
        )}
      </form>
      {newAccountParam?.uid && hasUserAction && (
        <AtssNewAccountImageTag uid={newAccountParam?.uid} />
      )}
    </Dialog>
  );
}

const providerName = (provider: string) => {
  switch (provider) {
    case 'google.com':
      return 'Google';
    case 'facebook.com':
      return 'Facebook';
    case 'twitter.com':
      return 'Twitter';
    case 'apple.com':
      return 'Apple';
    default:
      return '他のソーシャル';
  }
};

const AtssNewAccountImageTag = ({ uid }: { uid: string }) => {
  const [rkParam, setRkParam] = useState('');
  const [verify, setVerify] = useState('');
  useEffect(() => {
    const cookie = parse(document?.cookie);
    if (cookie.atss_js) {
      setRkParam(cookie.atss_js);
    }
    if (cookie.atss_verify) {
      setVerify(cookie.atss_verify);
    }
  }, []);
  if (!isProduction()) return <></>;
  if (!uid || !rkParam || !verify) return <></>;

  return (
    <img
      src={`https://is.accesstrade.net/cgi-bin/isatV2/postprime/isatWeaselV2.cgi?result_id=103&verify=${verify}&rk=${rkParam}`}
      width={1}
      height={1}
    />
  );
};
