import { Dialog } from '@material-ui/core';
import React, { useState, ChangeEventHandler } from 'react';
import { useMutation } from 'react-query';
import useGlobalStore from 'store/global';
import styles from './ForgotPass.module.scss';
import { validEmail } from 'common/utils';
import Button from 'components/common/Button';
import axios from 'axios';
import Icon from 'components/common/Icon';

export default function ForgotPassDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(
    email ? '' : 'メールを入力してください'
  );
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const email = e.target.value;
    setEmail(email);
    if (!email) {
      setEmailError('メールを入力してください');
    } else if (!validEmail(e.target.value)) {
      setEmailError('正しいメールアドレスを入力してください');
    } else if (emailError) {
      setEmailError('');
    }
  };

  const { isLoading, mutate: sendMail } = useMutation(
    async (email: string) => {
      return axios.post('/users/reset-password', { email: email });
    },
    {
      onSuccess: () => {
        setSnackbarMessage({
          type: 'success',
          text: 'パスワードリセットを申請しました。メールを確認してください。',
        });
        handleClose();
      },
      onError: () => {
        setSnackbarMessage({ type: 'error', text: 'エラーが発生しました。' });
      },
    }
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className={styles.forgotPassDialogWrapper}>
        <div className={styles.header}>
          <p>パスワードの再設定</p>
          <div className={styles.close}>
            <Icon
              name="close-black"
              width={14}
              height={14}
              onClick={() => handleClose()}
            />
          </div>
        </div>
        <div className={styles.infoGroup}>
          <div className={styles.label}>メールアドレス</div>
          <div className={styles.value}>
            <input
              className={styles.input}
              value={email}
              type="email"
              onChange={handleEmailChange}
            />
          </div>
          <div className={styles.error}>{emailError}</div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            text="送信する"
            onClick={() => sendMail(email)}
            isLoading={isLoading}
            disabled={!!emailError}
          />
        </div>
      </div>
    </Dialog>
  );
}
