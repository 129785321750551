import classNames from 'classnames';
import { useOutsideClick } from 'common/utils';
import { useRef, useState } from 'react';
import styles from './HelpPopover.module.scss';

const HelpPopover = ({
  content,
  tooltipWidth = 220,
  className,
  iconElement,
  tooltipClassname,
}: {
  content: JSX.Element;
  tooltipWidth?: number;
  className?: string;
  iconElement?: JSX.Element;
  tooltipClassname?: string;
}) => {
  const [show, setShow] = useState(false);
  const tooltipRef = useRef();
  useOutsideClick(tooltipRef, () => {
    if (show) setShow(false);
  });

  return (
    <span
      className={classNames(styles.help, className)}
      ref={tooltipRef}
      onMouseEnter={() => setShow(true)}
    >
      {iconElement ? iconElement : `?`}
      <span
        className={classNames(styles.tooltip, tooltipClassname)}
        style={{ display: show ? '' : 'none', width: `${tooltipWidth}px` }}
      >
        {content}
      </span>
    </span>
  );
};

export default HelpPopover;
