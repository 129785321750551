import axios from 'axios';
import { GetTrendingTopicsResult } from 'common/interfaces/api';
import { useMediaQuery } from 'common/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Icon from 'components/common/Icon';
import useGlobalStore from 'store/global';
import styles from '../GlobalHeader.module.scss';
import GlobalPostTopicsList from '../GlobalPostTopicsList';
import { track } from '@amplitude/analytics-browser';
import Link from 'next/link';
import { useMountedDelay } from 'common/utils/hooks/useDidMount';

const TrendingTopics = () => {
  const router = useRouter();
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');
  const hashTagsRef = useRef<HTMLDivElement>(null);
  const hashTagsContainerRef = useRef<HTMLDivElement>(null);
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const mounted = useMountedDelay();
  const { data: trendingData } = useQuery(
    ['getTrendingKeywords', isAuthInit],
    async () => {
      if (!isAuthInit || !mounted) return Promise.reject();
      return (await axios.get(`/feeds/topics`)).data as GetTrendingTopicsResult;
    },
    {
      retry: 1,
      // refetch every 30s
      refetchInterval: 60000 * 10,
      enabled: isAuthInit && mounted,
      refetchOnWindowFocus: false,
    }
  );
  const trendingTopics = useMemo(
    () => trendingData?.data?.topics,
    [trendingData?.data?.topics]
  );
  const [isHover, setIsHover] = useState(false);
  const [scrollingDirection, setScrollingDirection] = useState(1);
  const scrollShowMoreTags = (offset: number) => {
    if (hashTagsContainerRef.current) {
      hashTagsContainerRef.current.scrollLeft += offset;
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        !hashTagsRef.current ||
        !hashTagsContainerRef.current ||
        (isHover && !isMobileScreen)
      )
        return;
      try {
        if (
          hashTagsContainerRef.current?.clientWidth + 100 <
          hashTagsRef.current?.clientWidth
        )
          return;
        if (
          hashTagsContainerRef.current?.clientWidth +
            hashTagsContainerRef.current?.scrollLeft >=
          hashTagsContainerRef.current?.scrollWidth - 4
        ) {
          setScrollingDirection(-1);
        } else if (hashTagsContainerRef.current?.scrollLeft <= 0) {
          setScrollingDirection(1);
        }
        scrollShowMoreTags(3 * scrollingDirection);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }, 50);
    return () => clearInterval(timer);
  }, [trendingTopics, isHover, scrollingDirection, isMobileScreen]);

  if (router.pathname.startsWith('/account')) return <></>;
  if (router.pathname.startsWith('/company')) return <></>;
  if (router.pathname.startsWith('/livestream/')) return <></>;
  if (router.pathname === '/p/membership' && isSmallScreen) return <></>;
  if (router.pathname.startsWith('/dashboard')) return <></>;
  return (
    <nav
      className={styles.secondLine}
      ref={hashTagsRef}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      aria-label="ハッシュタグ一覧"
    >
      {!isSmallScreen && (
        <div className={styles.globalPostTopics}>
          <GlobalPostTopicsList />
          <div
            className={styles.backWard}
            onClick={() => scrollShowMoreTags(-30)}
          >
            <Icon name="foward-arrow" width={24} height={24} />
          </div>
        </div>
      )}
      <div className={styles.topicContainer} ref={hashTagsContainerRef}>
        {(trendingTopics || []).map((topic, index) => {
          return (
            <Link
              href={`/topics/${topic.topic}`}
              key={`${topic.topic}_${index}`}
            >
              <a
                onClick={() => {
                  track('Click Tag Button', { tag_name: topic.topic });
                }}
              >
                <div className={styles.topicBtn}>#{topic.topic}</div>
              </a>
            </Link>
          );
        })}
      </div>
      <div className={styles.showMore} onClick={() => scrollShowMoreTags(30)}>
        <Icon name="foward-arrow" width={24} height={24} />
      </div>
    </nav>
  );
};

export default TrendingTopics;
